import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Blogs/Actions';
import { setHeaderNameAction } from './../../redux/MainLayout/Actions';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MUIRichTextEditor from 'mui-rte';
import ProfileImageUpload from './../../components/Profile/ProfileImageUpload';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${process.env.REACT_APP_BASE_PATH}/media/background.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const BlogValidationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter title'),
  description: Yup.object().required('Please enter a content'),
  image: Yup.mixed().required('Please select featured image'),
});

const BlogUpdateValidationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter title'),
  description: Yup.object().required('Please enter a content'),
});

const BlogAdd = (props) => {
  const {
    fetchedData,
    match: { params },
    saveBlogAction,
    updateBlogAction,
    setHeaderNameAction,
    resetBlogsDataAction,
    fetchSingleBlogAction,
  } = props;

  const classes = useStyles();
  const muiref = React.useRef(null);

  useEffect(() => {
    setHeaderNameAction(`Blogs ${params.id ? 'Edit' : 'Add'}`);
    return () => {
      resetBlogsDataAction();
    };
  }, [setHeaderNameAction, resetBlogsDataAction, params]);

  useEffect(() => {
    if(!fetchedData && params.id) {
      fetchSingleBlogAction(params.id);
    }
  }, [fetchedData, params, fetchSingleBlogAction])

  const handleFormSubmit = (values) => {
    const formData = new FormData();
    if (params.id) {
      const newValues = { ...values, blog_id: params.id };
      Object.keys(newValues).forEach(key => {
        if(newValues[key]) {
          formData.append(key, newValues[key]);
        }
      });
      updateBlogAction(formData);
    } else {
      Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
      })
      saveBlogAction(formData);
    }
  }

  let submitMyForm = null;

  const bindSubmitForm = (submitForm) => {
    submitMyForm = submitForm;
  };

  return (
    <div className="shadow p-4">
      {(params.id === undefined || (params.id && fetchedData)) &&
        <Formik
          enableReinitialize
          initialValues={{
            title: fetchedData ? fetchedData.title : '',
            description: fetchedData ? fetchedData.description : JSON.stringify({ "blocks": [{ "key": "1p3ls", "text": "", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }], "entityMap": {} }),
            image: undefined,
          }}
          onSubmit={handleFormSubmit}
          validationSchema={params.id ? BlogUpdateValidationSchema : BlogValidationSchema}
          validateOnChange={false}
        >
          {(renderProps) => {
            const { submitForm, values: formValues } = renderProps;
            bindSubmitForm(submitForm);
            return (
              <Form className={classes.form}>
                <TextField
                  label="Blog title"
                  className="mt-0"
                  style={{ width: '100%' }}
                  margin="normal"
                  fullWidth
                  id="title"
                  name="title"
                  autoFocus
                  value={formValues.title}
                  onChange={e => renderProps.setFieldValue('title', e.target.value)}
                  helperText={renderProps.errors.title || ''}
                  error={renderProps.errors.title ? true : false}
                />
                <div className="mt-3">
                  <FormLabel className="font-size-12 mb-2" component="legend">Featured Image</FormLabel>
                  <ProfileImageUpload className="business-profile" bgimage={''} uploadFile={(file) => renderProps.setFieldValue('image', file || null)} />
                  <ErrorMessage className="text-error font-size-12 mt-2" name="image" component="div" />
                </div>
                <div className="mt-3">
                  <FormLabel className="font-size-12 mb-2" component="legend">Blog Content <strong>(Press last save icon after writing.)</strong></FormLabel>
                  <MUIRichTextEditor
                    value={formValues.description}
                    label="Start typing..."
                    onSave={state => renderProps.setFieldValue('description', state)}
                    error={renderProps.errors.description ? true : false}
                    ref={muiref}
                  />
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={e => {
                    muiref.current.save();
                    return submitMyForm(e);
                  }}
                >
                  {params.id ? 'Update' : 'Add'} Blog
                </Button>
              </Form>
            );
          }}
        </Formik>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  ...state.Blogs
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogAdd);