import React from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Login/Actions';

const Orders = (props) => {
  return (
    <div>
      Orders
    </div>
  )
}

const mapStateToProps = (state) => ({
  ...state.Login
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
