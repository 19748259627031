import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 220,
  },
  submit: {
    width: 100
  },
}));


const ReportedStolenCarsActionDialog = ({ open, handleClose,
  ReportedStolenCarData, handleChangeReportedStolenCarsStatus }) => {
  const [status, setStatus] = useState('inactive');
  const classes = useStyles();

  const handleSubmitStatus = e => {
    e && e.preventDefault();
    const useStatus = status;
    setStatus('inactive');
    handleChangeReportedStolenCarsStatus({ _id: ReportedStolenCarData._id, status: useStatus })
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Reported Stolen Car Actions</DialogTitle>
      <DialogContent>
        {ReportedStolenCarData &&
          <React.Fragment>
            <Typography className="mb-2 font-size-16" component="h3" variant="h6">
              Account
            </Typography>
            <form style={{ display: 'flex' }} onSubmit={e => e.preventDefault()}>
              <FormControl className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
              <Button
                fullWidth
                className={classes.submit}
                variant="contained"
                color="primary"
                style={{ marginLeft: 10, alignSelf: 'flex-end' }}
                onClick={handleSubmitStatus}
              >
                Submit
              </Button>
            </form>
          </React.Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReportedStolenCarsActionDialog;