import React, { useEffect, useState } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Subscriptions/Actions';
import { setHeaderNameAction } from './../../redux/MainLayout/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TextField from "@material-ui/core/TextField";
// import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

// import TablePaginationActions from './../Users/TablePaginationActions';
import Loader from './../../components/Loader';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles({
  table: {
    minWidth: 500
  },
});

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'ads', label: 'Ads', minWidth: 170 },
  { id: 'price', label: 'Price', minWidth: 170 },
  { id: 'sub_type', label: 'Type', minWidth: 170 },
  { id: 'status', label: 'Status', minWidth: 170 },
  { id: 'actions', label: 'Actions', minWidth: 170 },
];

const Subscriptions = (props) => {
  const {
    limit,
    currentPage,
    loading,
    subscriptionsData,
    fetchAllSubscriptionsAction,
    resetSubscriptionsDataAction,
    updateSubscriptionAction,
    setHeaderNameAction,
  } = props;

  const classes = useStyles();

  const [price, setPrice] = useState([]);

  useEffect(() => {
    setHeaderNameAction('Subscriptions');
    return () => {
      resetSubscriptionsDataAction();
    };
  }, [setHeaderNameAction, resetSubscriptionsDataAction]);

  useEffect(() => {
    if (loading) {
      fetchAllSubscriptionsAction();
    }
  }, [loading, fetchAllSubscriptionsAction]);

  const handleUpdatePrice = (subscription, price) => {
    console.log('subscription, price', subscription, price);
    updateSubscriptionAction({...subscription, price, sub_id: subscription._id})
  }

  const emptyRows = limit - Math.min(limit, subscriptionsData.length - currentPage * limit);

  return (
    loading ?
      <Loader />
      :
      <TableContainer
        component={Paper}
      // style={{ height: 'calc(100vh - 180px)' }}
      >
        <Table stickyHeader className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                // style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionsData.length > 0 ?
              subscriptionsData.map((subscription, index) => {
                
                return (
                  <TableRow key={subscription._id}>
                    <TableCell component="th" scope="row">
                      {subscription.name}
                    </TableCell>
                    <TableCell>{subscription.number}</TableCell>
                    <TableCell>
                      {/* {subscription.price} */}
                      <TextField
                        // label="Price"
                        margin="normal"
                        name="price"
                        InputProps={{
                          type: 'number',
                        }}
                        required
                        defaultValue={subscription.price}
                        onChange={e => {
                          let newPrice = [...price];
                          newPrice[index] = Number(e.target.value);
                          setPrice(newPrice);
                        }}
                      />
                    </TableCell>
                    <TableCell>{subscription.sub_type}</TableCell>
                    <TableCell>{subscription.status}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleUpdatePrice(subscription, price[index] || subscription.price)}>
                        <SaveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })
              :
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            }
          </TableBody>
          {/* <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={6}
                count={totalResults}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                  defaultValue: limit,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeLimit}
                ActionsComponent={TablePaginationActions}
                rowsPerPage={limit}
                page={currentPage - 1}
              />
            </TableRow>
          </TableFooter> */}
        </Table>
      </TableContainer>
  )
}

const mapStateToProps = (state) => ({
  ...state.Subscriptions
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);