import React, { useEffect, useState } from 'react'
// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/DealerCars/Actions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { setHeaderNameAction } from '../../redux/MainLayout/Actions';
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import TablePaginationActions from './TablePaginationActions'
import Loader from '../../components/Loader'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
    },
    rootPaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    carImage:{
        width: 50,
        height: 50,
        borderRadius:"50%",
        objectFit:"cover",
        border: "1px solid black"
    }
}))

const columns = [
    { id: 'featured_image', label: 'Image', minWidth: 140,align: 'center'  },
    { id: 'title', label: 'Title', minWidth: 140,align: 'center'  },
    { id: 'fueltype', label: 'FuelType', minWidth: 140,align: 'center'  },
    { id: 'price', label: 'Price', minWidth: 140,align: 'center'  },
    { id: 'condition', label: 'Condition', minWidth: 140,align: 'center'  },
    { id: 'status', label: 'Status', minWidth: 140, align: 'center' },
    { id: 'year', label: 'Year', minWidth: 140,align: 'center'  },
    { id: 'views', label: 'Views', minWidth: 140,align: 'center'  },
    { id: 'actions', label: 'Actions', minWidth: 170, align: 'center' },
]

const DealerCars = (props) => {
    const {
        limit,
        currentPage,
        totalResults,
        loading,
        dealerCarsData,
        fetchAllDealerCarsAction,
        resetDealerCarsDataAction,
        changePageNumberAction,
        changePageLimitAction,
        setHeaderNameAction,
        searchTermAddedAction,
        match: { params }
    } = props
    const classes = useStyles()
    const [searchTerm, setSearchTerm] = useState('')

    /** Set header name and reset dealer cars data on component mount */
    useEffect(() => {
        setHeaderNameAction('Dealer Cars');
        return () => {
            resetDealerCarsDataAction();
        }
    }, [setHeaderNameAction, resetDealerCarsDataAction]);

    /** Fetch dealer cars data when limit, currentPage, loading, or searchTerm changes*/
    useEffect(() => {
        if (loading) {
            const payload = {
                page: currentPage,
                limit,
                search: searchTerm,
                dealerId:params.id
            }
            fetchAllDealerCarsAction(payload)
        }
    }, [limit, currentPage, loading, fetchAllDealerCarsAction, searchTerm,params?.id])

    /** Calculate the number of empty rows for pagination*/ 
    const emptyRows =
        limit - Math.min(limit, dealerCarsData?.length - currentPage * limit)

    /**Handle page change */
    const handleChangePage = (event, newPage) => {
        changePageNumberAction(newPage + 1)
    }
    /**Handle rows per page change */
    const handleChangeLimit = (event) => {
        changePageLimitAction(parseInt(event.target.value, 10))
    }
    /**Handle search form submission */
    const handleSearchSubmit = (e) => {
        e && e.preventDefault()

        searchTermAddedAction()
    }
    return (
        <React.Fragment>
            <div className="d-flex justify-content-end mb-3">
                <Paper
                    component="form"
                    className={classes.rootPaper}
                    onSubmit={handleSearchSubmit}
                >
                    <InputBase
                        className={classes.input}
                        placeholder="Search Dealer Cars"
                        inputProps={{ 'aria-label': 'search dealer cars' }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <IconButton
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={handleSearchSubmit}
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <React.Fragment>
                    <TableContainer
                        component={Paper}
                    // style={{ height: 'calc(100vh - 180px)' }}
                    >
                        <Table
                            stickyHeader
                            className={classes.table}
                            aria-label="custom pagination table"
                        >
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dealerCarsData?.length > 0 ? (
                                    dealerCarsData.map((dealerCar, index) => (
                                        <TableRow key={dealerCar._id}>
                                           <TableCell component="th" scope="row" align="center">
                                           <img src={dealerCar.featured_image} className={classes.carImage} alt='car'/>
                                            </TableCell>
                                            <TableCell align='center'>
                                            {`${dealerCar.make} ${dealerCar.model} ${dealerCar.year}`}                                            </TableCell>
                                            <TableCell align="center">{dealerCar.fueltype}</TableCell>
                                            <TableCell align="center">R{(dealerCar.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</TableCell>                                            <TableCell align="center">{dealerCar.condition}</TableCell>
                                            <TableCell align="center">
                                                 {dealerCar.status === 'active' ?
                                                                       'Active'
                                                                                :
                                                  dealerCar.status === 'blocked' ?
                                                  'Blocked'
                                                                                 :
                                                  dealerCar.status === 'suspended' ?
                                                 'Suspended'
                                                                                 :
                                                 'Deleted'
                                                  }
                                            </TableCell>
                                            <TableCell>{dealerCar.year}</TableCell>
                                            <TableCell align="center">{dealerCar.views.length}</TableCell>
                                            <TableCell align="center">
                                               <IconButton component={"a"} target="_blank" href={`${process.env.REACT_APP_MAIN_SITE_URL}/listing/${dealerCar.slug}`}>
                                                  <VisibilityIcon />
                                              </IconButton>
                                              <IconButton component={Link} to={`/listings/${dealerCar.slug}`}>
                                                  <EditIcon />
                                              </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow style={{ height: 2 * emptyRows }}>
                                        <TableCell colSpan={6} >
                                          <h1>  No Data Avaiable</h1>
                                            </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={6}
                                        count={totalResults}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                            defaultValue: limit,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeLimit}
                                        ActionsComponent={TablePaginationActions}
                                        rowsPerPage={limit}
                                        page={currentPage - 1}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    ...state.DealerCars
})

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerCars)


