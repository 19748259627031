import React, { useState, useRef } from 'react'
import { ProfileImage } from './Profile.style';

const ProfileImageUpload = ({ uploadFile, bgimage, className, needValidation, receiveError }) => {
  const [uploadedProfileImg, setUploadedProfileImg] = useState(undefined);
  const fileUpload = useRef(null);

  return (
    <ProfileImage className={`${className} shadow`} bgimage={uploadedProfileImg ? uploadedProfileImg.value : bgimage && bgimage !== '' ? bgimage : false }>
      <span className={`profile-photo-edit__file-upload-input cursor-pointer user-select-none`} onClick={() => fileUpload.current.click()} />
      <input
        type="file"
        className="d-none"
        ref={fileUpload}
        accept="image/*"
        onChange={(e) => {
          if (e.target.files.length === 0) {
            setUploadedProfileImg(undefined);
            uploadFile(undefined)
            return;
          } else {
            const reader = new FileReader();
            const file = e.target.files[0];
            const filetype = file.type;
            const filesize = file.size;
            let validate = true;
            reader.readAsDataURL(file);
            reader.onload = (event) => {
              let payload = undefined;
              let validate = true;

              if(needValidation) {
                if (filesize > 1500000) {
                  validate = false;
                  receiveError('File size must be less than or equal to 1.5MB');
                }
              }

              if (filetype.includes('image/') && validate) {
                payload = {
                  type: 'image',
                  value: event.target.result,
                }
              }

              setUploadedProfileImg(payload);
            };

            // setUploadedProfileImgObj(e.target.files[0]);
            if(needValidation) {
              if (filesize > 1500000) {
                validate = false;
                receiveError('File size must be less than or equal to 1.5MB');
              }
            }

            if(validate) {
              uploadFile(e.target.files[0]);
            }
          }
        }}
      />
    </ProfileImage>
  )
}

export default ProfileImageUpload
