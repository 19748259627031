import Dashboard from "./Dashboard/Reducer";
import Notification from "./Notification/Reducer";
import Login from "./Login/Reducer";
import Users from "./Users/Reducer";
import Technicians from "./Technicians/Reducer";
import Dealers from "./Dealers/Reducer";
import DealerCars from "./DealerCars/Reducer";
import ChatReportedUsers from "./ChatReportedUsers/Reducer";
import Insurances from "./Insurances/Reducer";
import ReportedStolenCars from "./ReportedStolenCars/Reducer";
import MainLayout from "./MainLayout/Reducer";
import CarListing from "./CarListing/Reducer";
import CarEdit from "./CarEdit/Reducer";
import TestDrive from "./TestDrive/Reducer";
import ContactDetails from "./ContactDetails/Reducer";
import Blogs from "./Blogs/Reducer";
import Subscriptions from "./Subscriptions/Reducer";
import MakeModel from "./MakeModel/Reducer";

export default {
  Dashboard,
  Notification,
  Login,
  Users,
  Technicians,
  Insurances,
  ChatReportedUsers,
  ReportedStolenCars,
  MainLayout,
  CarListing,
  CarEdit,
  TestDrive,
  ContactDetails,
  Blogs,
  Subscriptions,
  Dealers,
  MakeModel,
  DealerCars
}