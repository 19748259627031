import React, { useEffect, useState } from 'react'
// For redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Actions from '../../redux/ChatReportedUsers/Actions'
import { setHeaderNameAction } from '../../redux/MainLayout/Actions'


import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@mui/material/Button';

import TablePaginationActions from './TablePaginationActions'
import Loader from '../../components/Loader'


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
    },
    rootPaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}))


const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Cell', minWidth: 170 },
    { id: 'phone', label: 'Email', minWidth: 170 },
    { id: 'status', label: 'Status', minWidth: 170 },
]


const ChatReportedUsers = (props) => {

    const {
        limit,
        currentPage,
        totalResults,
        loading,
        ChatReportedUsersData,
        fetchAllChatReportedUsersDataAction,
        updateChatReportedUserStatusAction,
        resetChatReportedUsersDataAction,
        changePageNumberAction,
        changePageLimitAction,
        setHeaderNameAction,
        searchTermAddedAction,
    } = props

    const classes = useStyles()
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        setHeaderNameAction('Chat Reported Users');
        return () => {
            resetChatReportedUsersDataAction();
        }
    }, [setHeaderNameAction, resetChatReportedUsersDataAction]);

    useEffect(() => {
        if (loading) {
            const payload = {
                page: currentPage,
                limit,
                search: searchTerm,
            }
            fetchAllChatReportedUsersDataAction(payload)
        }
    }, [limit, currentPage, loading, fetchAllChatReportedUsersDataAction, searchTerm])


    const emptyRows =
        limit - Math.min(limit, ChatReportedUsersData.length - currentPage * limit)

    const handleChangePage = (event, newPage) => {
        changePageNumberAction(newPage + 1)
    }

    const handleChangeLimit = (event) => {
        changePageLimitAction(parseInt(event.target.value, 10))
    }


    const handleChatReportedUser = (v) => {
        updateChatReportedUserStatusAction({ user_id: v })
    }

    const handleSearchSubmit = (e) => {
        e && e.preventDefault()

        searchTermAddedAction()
    }

    return (
        <>
            <React.Fragment>
                <div className="d-flex justify-content-end mb-3">
                    <Paper
                        component="form"
                        className={classes.rootPaper}
                        onSubmit={handleSearchSubmit}
                    >
                        <InputBase
                            id='chatreportedsearch'
                            autoComplete="off"
                            className={classes.input}
                            placeholder="Search ChatReportedUsers"
                            inputProps={{ 'aria-label': 'search chat-reported-users' }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            onClick={handleSearchSubmit}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <TableContainer
                            component={Paper}
                        >
                            <Table
                                stickyHeader
                                className={classes.table}
                                aria-label="custom pagination table"
                            >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            // style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ChatReportedUsersData.length > 0 ? (
                                        ChatReportedUsersData.map((reportedUser, index) => (
                                            <TableRow key={reportedUser._id}>
                                                <TableCell component="th" scope="row">
                                                    {reportedUser.fullname}
                                                </TableCell>
                                                <TableCell>{reportedUser.gsm}</TableCell>
                                                <TableCell>{reportedUser.email}</TableCell>
                                                <TableCell>
                                                    <Button id='dismiss' onClick={() => handleChatReportedUser(reportedUser._id)} variant="outlined" color="error">
                                                        Dismiss Report
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow style={{ height: 2 * emptyRows }}>
                                        <TableCell colSpan={6} >
                                          <h1>  No Data Avaiable</h1>
                                            </TableCell>
                                    </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={6}
                                            count={totalResults}
                                            SelectProps={{
                                                id: "pagination",
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                                defaultValue: limit,
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeLimit}
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPage={limit}
                                            page={currentPage - 1}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )}
            </React.Fragment>
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state.ChatReportedUsers,
})

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatReportedUsers)
