import {
  FETCH_SUBSCRIPTIONS,
  RESET_SUBSCRIPTIONS,
  UPDATE_PAGE_NUMBER,
  UPDATE_PAGE_LIMIT,
} from './Constants';
import { ADDED_NOTIFICATION } from "../Notification/Constants";

import Api from '../../helpers/ApiHandler';
const api = new Api();

export const fetchAllSubscriptionsAction = () => dispatch => {
  api.get(`admin_master/get-subscription-list`)
    .then(result => {
      if (result.success) {
        dispatch({
          type: FETCH_SUBSCRIPTIONS,
          data: {
            subscriptionsData: result.data,
          }
        })
      } else {
        dispatch({
          type: FETCH_SUBSCRIPTIONS,
          data: {
            subscriptionsData: [],
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const updateSubscriptionAction = (payload) => dispatch => {
  api.put(`admin_master/update-subscription`, { data: payload})
    .then(result => {
      if (result.success) {
        dispatch(fetchAllSubscriptionsAction())
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'success',
            message: 'Subscription price updated'
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const resetSubscriptionsDataAction = () => dispatch => {
  dispatch({
    type: RESET_SUBSCRIPTIONS
  })
}

export const changePageNumberAction = (pageNumber) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_NUMBER,
    data: pageNumber
  })
}

export const changePageLimitAction = (limit) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_LIMIT,
    data: limit
  })
}