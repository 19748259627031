import {
  FETCH_SUBSCRIPTIONS,
  RESET_SUBSCRIPTIONS,
  // UPDATE_PAGE_NUMBER,
  // UPDATE_PAGE_LIMIT,
  DELETE_SUBSCRIPTIONS,
} from './Constants';

const initialState = {
  subscriptionsData: [],
  loading: true,
  // totalResults: 0,
  currentPage: 1,
  limit: 25,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ...action.data,
        loading: false,
      }
    // case UPDATE_PAGE_NUMBER:
    //   return {
    //     ...state,
    //     currentPage: action.data,
    //     loading: true,
    //   }
    // case UPDATE_PAGE_LIMIT:
    //   return {
    //     ...state,
    //     limit: action.data,
    //     loading: true,
    //   }
    case DELETE_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsData: state.subscriptionsData.filter(x => x._id !== action.data),
        // totalResults: state.totalResults - 1
      }
    case RESET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsData: [],
        loading: true,
        totalResults: 0,
        currentPage: 1,
        limit: 25,
      }
    default:
      return state
  }
}