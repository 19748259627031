import React, { useEffect, useState } from 'react'
// For redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Actions from '../../redux/ReportedStolenCars/Actions'
import { setHeaderNameAction } from '../../redux/MainLayout/Actions'


import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@mui/material/Button';
import TablePaginationActions from './TablePaginationActions'
import Loader from './../../components/Loader'
import ReportedStolenCarsActionDialog from './../../components/ReportedStolenCars/ReportedStolenCarsActionDialog'
import ImagesActionDialog from './../../components/ReportedStolenCars/ImagesActionDialog'


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
    },
    rootPaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}))


const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
    { id: 'gsm', label: 'Cell', minWidth: 170 },
    { id: 'plate_number', label: 'Plate_Number', minWidth: 170 },
    { id: 'images', label: 'Images', minWidth: 170 },
    { id: 'status', label: 'Status', minWidth: 170 },
    { id: 'actions', label: 'Actions', minWidth: 170 },
    { id: 'delete', label: 'Delete', minWidth: 170 },
]

const ReportedStolenCars = (props) => {

    const {
        limit,
        currentPage,
        totalResults,
        loading,
        ReportedStolenCarsData,
        fetchAllReportedStolenCarsAction,
        resetReportedStolenCarsDataAction,
        changePageNumberAction,
        changePageLimitAction,
        updateReportedStolenCarsAction,
        setHeaderNameAction,
        searchTermAddedAction,
        notificationLoading,
        deleteReportedStolenCar,
    } = props


    const classes = useStyles()
    const [selectedImageIndex, setSelectedImageIndex] = useState(null)
    const [selectedUserIndex, setSelectedUserIndex] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [isUserDialogOpen, setIsUserDialogOpen] = useState(false)
    const [isImageDialogOpen, setIsImageDialogOpen] = useState(false)
    const [selectedCarsImages, setselectedCarsImages] = useState([])
    const [searchTerm, setSearchTerm] = useState('')


    useEffect(() => {
        setHeaderNameAction('Reported Stolen Cars');
        return () => {
            resetReportedStolenCarsDataAction();
        }
    }, [setHeaderNameAction, resetReportedStolenCarsDataAction]);


    useEffect(() => {
        if (loading) {
            const payload = {
                page: currentPage,
                limit,
                search: searchTerm,
            }
            fetchAllReportedStolenCarsAction(payload)
        }
    }, [limit, currentPage, loading, fetchAllReportedStolenCarsAction, searchTerm])

    const emptyRows =
        limit - Math.min(limit, ReportedStolenCarsData.length - currentPage * limit)

    const handleChangePage = (event, newPage) => {
        changePageNumberAction(newPage + 1)
    }

    const handleChangeLimit = (event) => {
        changePageLimitAction(parseInt(event.target.value, 10))
    }

    const handleSelectStolenCarReport = (index) => {
        setSelectedUserIndex(index)
        setIsUserDialogOpen(true)
    }

    const handleSelectStolenCarImages = (index) => {
        setselectedCarsImages(ReportedStolenCarsData[index].images);
        setSelectedImageIndex(index)
        setIsImageDialogOpen(true)
    }

    const handleSearchSubmit = (e) => {
        e && e.preventDefault()
        searchTermAddedAction()
    }

    const handleCloseUserDialog = () => {
        setIsUserDialogOpen(false)
        setSelectedUserIndex(null)
    }
    const handleCloseimageDialog = () => {
        setIsImageDialogOpen(false)
        setSelectedImageIndex(null)
    }

    let ReportedStolenCarData = undefined
    if (selectedUserIndex !== null) {
        ReportedStolenCarData = { ...ReportedStolenCarsData[selectedUserIndex] }
    }

    let ReportedStolenCarimageData = undefined
    if (selectedImageIndex !== null) {
        ReportedStolenCarimageData = { ...ReportedStolenCarsData[selectedImageIndex] }
    }

    const handleChangeReportedStolenCarsStatus = (payload) => {
        updateReportedStolenCarsAction(payload)
    }

    const handleDelte = (_id) => {
        const result = window.confirm("Are you sure you want to delete?");
        console.log(result);
        if (result) {
            deleteReportedStolenCar({ _id })
        }
    }


    return (
        <>
            <React.Fragment>
                <div className="d-flex justify-content-end mb-3">
                    <Paper
                        component="form"
                        className={classes.rootPaper}
                        onSubmit={handleSearchSubmit}
                    >
                        <InputBase
                            className={classes.input}
                            placeholder="Search Reported Stolen Cars"
                            inputProps={{ 'aria-label': 'serach reported stolen cars' }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            onClick={handleSearchSubmit}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <TableContainer
                            component={Paper}
                        >
                            <Table
                                stickyHeader
                                className={classes.table}
                                aria-label="custom pagination table"
                            >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            // style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ReportedStolenCarsData.length > 0 ? (
                                        ReportedStolenCarsData.map((reportedStolenCar, index) => (
                                            <TableRow key={reportedStolenCar._id}>
                                                <TableCell component="th" scope="row">
                                                    {reportedStolenCar.fullname}
                                                </TableCell>
                                                <TableCell>{reportedStolenCar.email}</TableCell>
                                                <TableCell>{reportedStolenCar.gsm}</TableCell>
                                                <TableCell>{reportedStolenCar.plate_number}</TableCell>
                                                {/* <TableCell>{reportedStolenCar.proof_id}</TableCell> */}
                                                <TableCell>
                                                    <img style={{ width: '80px', height: '60px', borderRadius: '4px' }} alt="images" onClick={() => handleSelectStolenCarImages(index)} src={reportedStolenCar.images[0]} />
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        reportedStolenCar._id === selectedUser && notificationLoading ?
                                                            <Loader /> :
                                                            (reportedStolenCar.status === 'active' ? "Active" : "Inactive")
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => { handleSelectStolenCarReport(index); setSelectedUser(reportedStolenCar._id) }}>
                                                        <PermIdentityIcon />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    <Button id='dismiss' variant="contained" onClick={() => handleDelte(reportedStolenCar._id)} color="error">
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow style={{ height: 2 * emptyRows }}>
                                        <TableCell colSpan={6} >
                                          <h1>  No Data Avaiable</h1>
                                            </TableCell>
                                    </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={6}
                                            count={totalResults}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                                defaultValue: limit,
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeLimit}
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPage={limit}
                                            page={currentPage - 1}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        <ReportedStolenCarsActionDialog
                            open={isUserDialogOpen}
                            handleClose={handleCloseUserDialog}
                            ReportedStolenCarData={ReportedStolenCarData}
                            handleChangeReportedStolenCarsStatus={handleChangeReportedStolenCarsStatus}
                        />
                        <ImagesActionDialog
                            open={isImageDialogOpen}
                            handleClose={handleCloseimageDialog}
                            ReportedStolenCarimageData={ReportedStolenCarimageData}
                            selectedCarsImages={selectedCarsImages}
                            handleChangeReportedStolenCarsStatus={handleChangeReportedStolenCarsStatus}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state.ReportedStolenCars,
})

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportedStolenCars)
