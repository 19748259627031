import {
    FETCH_INSURANCE,
    RESET_INSURANCE,
    UPDATE_PAGE_NUMBER,
    UPDATE_PAGE_LIMIT,
    SEARCH_TERM_ADDED
} from './Constants';

const initialState = {
    InsurancesData: [],
    loading: true,
    totalResults: 0,
    currentPage: 1,
    limit: 25,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INSURANCE:
            return {
                ...state,
                ...action.data,
                loading: false,
            }
        case UPDATE_PAGE_NUMBER:
            return {
                ...state,
                currentPage: action.data,
                loading: true,
            }
        case UPDATE_PAGE_LIMIT:
            return {
                ...state,
                limit: action.data,
                loading: true,
            }
        case SEARCH_TERM_ADDED:
            return {
                ...state,
                loading: true,
                currentPage: 1,
                limit: 25
            }
        case RESET_INSURANCE:
            return {
                ...state,
                InsurancesData: [],
                loading: true,
                totalResults: 0,
                currentPage: 1,
                limit: 25,
            }
        default:
            return state
    }
}