import { FETCH_CHATREPORTEDUSERS, RESET_CHATREPORTEDUSERS, UPDATE_CHATREPORTEDUSERS, UPDATE_PAGE_NUMBER, UPDATE_PAGE_LIMIT, SEARCH_TERM_ADDED } from './Constants';
import { ADDED_NOTIFICATION } from "../Notification/Constants";
import Api from '../../helpers/ApiHandler';

const api = new Api();

export const fetchAllChatReportedUsersDataAction = (payload) => (dispatch) => {
    api.post(`admin_master/get-chat-reported-users-list`, { data: payload })
        .then((result) => {
            if (result.success) {
                dispatch({
                    type: FETCH_CHATREPORTEDUSERS,
                    data: {
                        ChatReportedUsersData: result.data,
                        totalResults: result.totalCount,
                    },
                });
            } else {
                dispatch({
                    type: FETCH_CHATREPORTEDUSERS,
                    data: {
                        ChatReportedUsersData: [],
                        totalResults: 0,
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err);
        });
};

export const resetChatReportedUsersDataAction = () => (dispatch) => {
    dispatch({
        type: RESET_CHATREPORTEDUSERS,
    });
};

export const changePageNumberAction = (pageNumber) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_NUMBER,
        data: pageNumber,
    });
};

export const changePageLimitAction = (limit) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_LIMIT,
        data: limit,
    });
};

export const searchTermAddedAction = () => (dispatch) => {
    dispatch({
        type: SEARCH_TERM_ADDED,
    });
};

export const updateChatReportedUserStatusAction = (payload) => (dispatch) => {
    api.put(`admin_master/update-chat-reported-users-details`, { data: payload })
        .then((result) => {
            if (result.success) {
                dispatch({
                    type: UPDATE_CHATREPORTEDUSERS,
                    data: result.data,
                });
                dispatch({
                    type: ADDED_NOTIFICATION,
                    data: {
                        type: "success",
                        message: "Dismiss Report Successfully",
                    },
                });
            } else {
                dispatch({
                    type: ADDED_NOTIFICATION,
                    data: {
                        type: "error",
                        message: "Something went wrong",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err);
        });
};