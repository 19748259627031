import {
  FETCH_USERS,
  RESET_USERS,
  UPDATE_PAGE_NUMBER,
  UPDATE_PAGE_LIMIT,
  SEARCH_TERM_ADDED,
  FETCH_SUBSCRIPTIONS,
  APPLY_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_USER,
} from './Constants';

const initialState = {
  usersData: [],
  subscriptionsData: [],
  loading: true,
  isSubscriptionsLoading: true,
  totalResults: 0,
  currentPage: 1,
  limit: 25,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        ...action.data,
        loading: false,
      }
    case FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ...action.data,
        isSubscriptionsLoading: false
      }
    case UPDATE_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.data,
        loading: true,
      }
    case UPDATE_PAGE_LIMIT:
      return {
        ...state,
        limit: action.data,
        loading: true,
      }
    case APPLY_SUBSCRIPTION:
    case UPDATE_SUBSCRIPTION:
    case UPDATE_USER:
      return {
        ...state,
        usersData: state.usersData.map(x => {
          if(x._id === action.data._id) {
            return action.data
          }

          return x;
        })
      }
    case SEARCH_TERM_ADDED:
      return {
        ...state,
        loading: true,
        currentPage: 1,
        limit: 25
      }
    case RESET_USERS:
      return {
        ...state,
        usersData: [],
        loading: true,
        totalResults: 0,
        currentPage: 1,
        limit: 25,
      }
    default:
      return state
  }
}