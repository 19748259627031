import {
  FETCH_VEHICLE_BRAND_CAR_EDIT,
  FETCH_VEHICLE_MODEL_CAR_EDIT,
  FETCH_CAR_DATA,
  FETCH_CAR_DATA_FAILURE,
  REMOVE_MODEL_OPTIONS,
  RESET_CAR_EDIT_OPTION,
  ADD_CAR,
  ADD_CAR_FAILURE,
  UPDATE_CAR,
  UPDATE_CAR_FAILURE,
  SET_LOADING,
  DELETE_CAR_IMAGE,
} from './Constants';

const initialState = {
  editCarData: undefined,
  vehicleBrandsList: [],
  vehicleModelsList: [],
  isLoading: false,
  message: '',
  hasError: false,
  hasSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_BRAND_CAR_EDIT:
      return {
        ...state,
        vehicleBrandsList: action.data
      }
    case FETCH_VEHICLE_MODEL_CAR_EDIT:
      return {
        ...state,
        vehicleModelsList: action.data
      }
    case REMOVE_MODEL_OPTIONS:
      return {
        ...state,
        vehicleModelsList: []
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case ADD_CAR:
      return {
        ...state,
        message: action.data,
        hasSuccess: true,
        isLoading: false,
      }
    case ADD_CAR_FAILURE:
      return {
        ...state,
        message: action.data,
        hasError: true,
        isLoading: false,
      }
    case UPDATE_CAR:
      return {
        ...state,
        message: action.data,
        hasSuccess: true,
        isLoading: false,
      }
    case UPDATE_CAR_FAILURE:
      return {
        ...state,
        message: action.data,
        hasError: true,
        isLoading: false,
      }
    case FETCH_CAR_DATA:
      return {
        ...state,
        editCarData: action.data
      }
    case FETCH_CAR_DATA_FAILURE:
      return {
        ...state,
        hasError: true,
        message: action.data
      }
    case DELETE_CAR_IMAGE:
      return {
        ...state,
        editCarData: {
          ...state.editCarData,
          images: state.editCarData.images.filter(x => x !== action.data)
        }
      }
    case RESET_CAR_EDIT_OPTION:
      return {
        editCarData: undefined,
        vehicleBrandsList: [],
        vehicleModelsList: [],
        isLoading: false,
        message: '',
        hasError: false,
        hasSuccess: false,
      }
    default:
      return state;
  }
}