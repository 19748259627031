import {
    FETCH_DEALERS,
    RESET_DEALERS,
    UPDATE_DEALERS,
    UPDATE_PAGE_NUMBER,
    UPDATE_PAGE_LIMIT,
    SEARCH_TERM_ADDED
} from './Constants';

/** Initial state for the dealers reducer */
const initialState = {
    dealersData: [],        // Array to hold dealer data
    loading: true,          // Indicates if data is being loaded
    totalResults: 0,        // Total number of results (for pagination)
    currentPage: 1,         // Current page number (for pagination)
    limit: 25,              // Limit of results per page (for pagination)
}

/** Reducer function for managing dealer-related state */
export default (state = initialState, action) => {
    switch (action.type) {
        /** Update state with fetched dealers data */
        case FETCH_DEALERS:
            return {
                ...state,
                ...action.data,  // Spread operator to merge action data with existing state
                loading: false, // Set loading to false as data has been fetched
            }
        /** Update state with new current page number */
        case UPDATE_PAGE_NUMBER:
            return {
                ...state,
                currentPage: action.data,  // Set current page to the value from action
                loading: true,             // Set loading to true as data is being updated
            }
        /** Update state with new limit for results per page */
        case UPDATE_PAGE_LIMIT:
            return {
                ...state,
                limit: action.data,  // Set limit to the value from action
                loading: true,       // Set loading to true as data is being updated
            }
        /** Update specific dealer data in state */
        case UPDATE_DEALERS:
            return {
                ...state,
                dealersData: state.dealersData.map(x => {
                    if (x._id === action.data._id) {
                        return action.data; // Replace existing dealer data with updated data
                    }
                    return x; // Keep other dealer data unchanged
                })
            }
        /** Reset state to initial values when search term is added */
        case SEARCH_TERM_ADDED:
            return {
                ...state,
                loading: true,    // Set loading to true as data is being updated
                currentPage: 1,  // Reset current page to first page
                limit: 25        // Reset limit to default value
            }
        /** Reset state to initial values */
        case RESET_DEALERS:
            return {
                ...state,
                dealersData: [],  // Clear dealer data
                loading: true,    // Set loading to true as data is being updated
                totalResults: 0,  // Reset total results to 0
                currentPage: 1,   // Reset current page to first page
                limit: 25         // Reset limit to default value
            }
        default:
            return state;  // Return current state if action type doesn't match
    }
}