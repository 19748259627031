import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(2),
    },
    sliderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        maxWidth: '100%',
        maxHeight: 400,
    },
}));

const ImagesActionDialog = ({ open, handleClose, selectedCarsImages }) => {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClose} onBackdropClick={handleClose} aria-labelledby="form-dialog-title">
            {selectedCarsImages &&
                <div className={classes.root}>
                    <div className={classes.sliderContainer}>
                        {/* <Button onClick={handlePrevious}>Previous</Button> */}
                        <Paper elevation={3}>
                            <img src={selectedCarsImages[0]} alt={`carImage`} className={classes.image} />
                        </Paper>
                        {/* <Button onClick={handleNext}>Next</Button> */}
                    </div>
                </div>
            }
        </Dialog>
    );
};

export default ImagesActionDialog;
