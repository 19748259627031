import React, { useEffect, useState } from 'react'
// For redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Actions from '../../redux/Insurances/Actions'
import { setHeaderNameAction } from '../../redux/MainLayout/Actions'


import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import TablePaginationActions from './TablePaginationActions'
import Loader from '../../components/Loader'


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
    },
    rootPaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}))


const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'phone', label: 'Cell', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
]

const Insurances = (props) => {
    const {
        limit,
        currentPage,
        totalResults,
        loading,
        InsurancesData,
        fetchAllInsurancesAction,
        resetInsurancesDataAction,
        changePageNumberAction,
        changePageLimitAction,
        setHeaderNameAction,
        searchTermAddedAction,
    } = props



    const classes = useStyles()
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        setHeaderNameAction('Insurance');
        return () => {
            resetInsurancesDataAction();
        }
    }, [setHeaderNameAction, resetInsurancesDataAction]);

    useEffect(() => {
        if (loading) {
            const payload = {
                page: currentPage,
                limit,
                search: searchTerm,
            }
            fetchAllInsurancesAction(payload)
        }
    }, [limit, currentPage, loading, fetchAllInsurancesAction, searchTerm])


    const emptyRows =
        limit - Math.min(limit, InsurancesData.length - currentPage * limit)

    const handleChangePage = (event, newPage) => {
        changePageNumberAction(newPage + 1)
    }

    const handleChangeLimit = (event) => {
        changePageLimitAction(parseInt(event.target.value, 10))
    }


    const handleSearchSubmit = (e) => {
        e && e.preventDefault()

        searchTermAddedAction()
    }

    return (
        <>
            <React.Fragment>
                <div className="d-flex justify-content-end mb-3">
                    <Paper
                        component="form"
                        className={classes.rootPaper}
                        onSubmit={handleSearchSubmit}
                    >
                        <InputBase
                            className={classes.input}
                            placeholder="Search Insurance"
                            inputProps={{ 'aria-label': 'search insurance' }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            onClick={handleSearchSubmit}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <TableContainer
                            component={Paper}
                        >
                            <Table
                                stickyHeader
                                className={classes.table}
                                aria-label="custom pagination table"
                            >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            // style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {InsurancesData.length > 0 ? (
                                        InsurancesData.map((insurance, index) => (
                                            <TableRow key={insurance._id}>
                                                <TableCell component="th" scope="row">
                                                    {insurance.fullname}
                                                </TableCell>
                                                <TableCell>{insurance.phone_number}</TableCell>
                                                <TableCell>{insurance.email}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow style={{ height: 2 * emptyRows }}>
                                        <TableCell colSpan={6} >
                                          <h1>  No Data Avaiable</h1>
                                            </TableCell>
                                    </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={6}
                                            count={totalResults}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                                defaultValue: limit,
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeLimit}
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPage={limit}
                                            page={currentPage - 1}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )}
            </React.Fragment>
        </>
    )
}


const mapStateToProps = (state) => ({
    ...state.Insurances,
})

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Insurances)