import React from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BrowserRouter } from 'react-router-dom';
import Routes from "./routes/Route";

import './styles/App.css';

// pick a date util library
import MomentUtils from '@date-io/moment';

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
        <Routes />
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  );
}

export default App;
