export const GET_CAR_SEARCH_RESULT_DATA = 'Results/GET_CAR_SEARCH_RESULT_DATA';
// export const GET_BRAND_LIST_FOR_RESULTS = 'Results/GET_BRAND_LIST_FOR_RESULTS';
// export const REMOVE_BRAND_FROM_SELECTED_LIST = 'Results/REMOVE_BRAND_FROM_SELECTED_LIST';
// export const UPDATE_BRANDS_FIELD_FILTER = 'Results/UPDATE_BRANDS_FIELD_FILTER';
// export const UPDATE_MODELS_FIELD_FILTER = 'Results/UPDATE_MODELS_FIELD_FILTER';
// export const UPDATE_FUEL_TYPE_FIELD_FILTER = 'Results/UPDATE_FUEL_TYPE_FIELD_FILTER';
// export const UPDATE_PRICE_FIELD_FILTER = 'Results/UPDATE_PRICE_FIELD_FILTER';
// export const UPDATE_KMS_DRIVEN_FIELD_FILTER = 'Results/UPDATE_KMS_DRIVEN_FIELD_FILTER';
// export const UPDATE_YEARS_FIELD_FILTER = 'Results/UPDATE_YEARS_FIELD_FILTER';
export const UPDATE_PAGE_NUMBER = 'Results/UPDATE_PAGE_NUMBER';
export const UPDATE_PAGE_LIMIT = 'Results/UPDATE_PAGE_LIMIT';
// export const UPDATE_SORT_BY_FILTER = 'Results/UPDATE_SORT_BY_FILTER';
// export const UPDATE_PROVINCE_FILTER = 'Results/UPDATE_PROVINCE_FILTER';
// export const UPDATE_CITY_FILTER = 'Results/UPDATE_CITY_FILTER';
// export const UPDATE_BODY_TYPE_FIELD_FILTER = 'Results/UPDATE_BODY_TYPE_FIELD_FILTER';
export const RESET_ALL_FITLERS = 'Results/RESET_ALL_FITLERS';
export const SEARCH_TERM_ADDED = 'Results/SEARCH_TERM_ADDED';
export const UPDATE_STATUS = 'Results/UPDATE_STATUS';
// export const CLEAR_FILTER_AND_DATA = 'Results/CLEAR_FILTER_AND_DATA';
// export const LIKE_UNLIKE_CAR = 'Results/LIKE_UNLIKE_CAR';
// export const LOAD_CITIES = 'Results/LOAD_CITIES';