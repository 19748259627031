import {
  GET_ALL_STATISTICS,
  RESET_DASHBOARD,
} from './Constants';

const initialState = {
  loading: true,
  count: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STATISTICS:
      return {
        ...state,
        count: action.data,
        loading: false,
      }
    case RESET_DASHBOARD:
      return {
        loading: true,
        count: undefined,
      }
    default:
      return state
  }
}