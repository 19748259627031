export const FETCH_VEHICLE_BRAND_CAR_EDIT = 'CarEdit/FETCH_VEHICLE_BRAND_CAR_EDIT';
export const FETCH_VEHICLE_MODEL_CAR_EDIT = 'CarEdit/FETCH_VEHICLE_MODEL_CAR_EDIT';
export const FETCH_CAR_DATA = 'CarEdit/FETCH_CAR_DATA';
export const FETCH_CAR_DATA_FAILURE = 'CarEdit/FETCH_CAR_DATA_FAILURE';
export const REMOVE_MODEL_OPTIONS = 'CarEdit/REMOVE_MODEL_OPTIONS';
export const RESET_CAR_EDIT_OPTION = 'CarEdit/RESET_CAR_EDIT_OPTION';
export const ADD_CAR = 'CarEdit/ADD_CAR';
export const ADD_CAR_FAILURE = 'CarEdit/ADD_CAR_FAILURE';
export const UPDATE_CAR = 'CarEdit/UPDATE_CAR';
export const UPDATE_CAR_FAILURE = 'CarEdit/UPDATE_CAR_FAILURE';
export const SET_LOADING = 'CarEdit/SET_LOADING';
export const DELETE_CAR_IMAGE = 'CarEdit/DELETE_CAR_IMAGE';