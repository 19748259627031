import React, { useEffect, useState } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/TestDrive/Actions';
import { setHeaderNameAction } from './../../redux/MainLayout/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import TablePaginationActions from './../Users/TablePaginationActions';
import Loader from './../../components/Loader';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  rootPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'mobile_number', label: 'Mobile Number', minWidth: 170 },
  { id: 'state', label: 'State', minWidth: 170 },
  { id: 'city', label: 'City', minWidth: 170 },
  { id: 'postcode', label: 'Post Code', minWidth: 170 },
  { id: 'car_brand', label: 'Brand', minWidth: 170 },
  { id: 'car_model', label: 'Model', minWidth: 170 },
  { id: 'datetime', label: 'Date & Time', minWidth: 170 },
  { id: 'status', label: 'Status', minWidth: 170 },
  { id: 'actions', label: 'Actions', minWidth: 170 },
];

const TestDrive = (props) => {
  const {
    limit,
    currentPage,
    totalResults,
    loading,
    testDriveData,
    fetchAllTestDrivesAction,
    resetTestDrivesDataAction,
    changePageNumberAction,
    changePageLimitAction,
    setHeaderNameAction,
    deleteTestDriveDetailAction,
    searchTermAddedAction,
  } = props;

  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setHeaderNameAction('Book Test Drive');
    return () => {
      resetTestDrivesDataAction();
    };
  }, [setHeaderNameAction, resetTestDrivesDataAction]);

  useEffect(() => {
    if (loading) {
      const payload = {
        page: currentPage,
        limit,
        search: searchTerm
      };
      fetchAllTestDrivesAction(payload);
    }
  }, [limit, currentPage, loading, fetchAllTestDrivesAction, searchTerm]);

  const emptyRows = limit - Math.min(limit, testDriveData.length - currentPage * limit);

  const handleChangePage = (event, newPage) => {
    changePageNumberAction(newPage + 1);
  };

  const handleChangeLimit = event => {
    changePageLimitAction(parseInt(event.target.value, 10));
  };

  const handleDeleteTestDriveDetail = id => {
    deleteTestDriveDetailAction(id);
  }

  const handleSearchSubmit = e => {
    e && e.preventDefault();

    searchTermAddedAction();
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end mb-3">
        <Paper component="form" className={classes.rootPaper} onSubmit={handleSearchSubmit}>
          <InputBase
            className={classes.input}
            placeholder="Search Booked Test Drive"
            inputProps={{ 'aria-label': 'search booked test drive' }}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <IconButton className={classes.iconButton} aria-label="search" onClick={handleSearchSubmit}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {loading ?
        <Loader />
        :
        <TableContainer
          component={Paper}
        // style={{ height: 'calc(100vh - 180px)' }}
        >
          <Table stickyHeader className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                  // style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {testDriveData.length > 0 ?
                testDriveData.map(testDrive => (
                  <TableRow key={testDrive._id}>
                    <TableCell component="th" scope="row">
                      {testDrive.fullname}
                    </TableCell>
                    <TableCell>{testDrive.email}</TableCell>
                    <TableCell>{testDrive.mobile_number}</TableCell>
                    <TableCell>{testDrive.state}</TableCell>
                    <TableCell>{testDrive.city}</TableCell>
                    <TableCell>{testDrive.postcode}</TableCell>
                    <TableCell>{testDrive.car_brand}</TableCell>
                    <TableCell>{testDrive.car_model}</TableCell>
                    <TableCell>{testDrive.datetime}</TableCell>
                    <TableCell>{testDrive.status}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteTestDriveDetail(testDrive._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={11} />
                </TableRow>
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={11}
                  count={totalResults}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                    defaultValue: limit,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeLimit}
                  ActionsComponent={TablePaginationActions}
                  rowsPerPage={limit}
                  page={currentPage - 1}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  ...state.TestDrive
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestDrive);