import {
    FETCH_DEALER_CARS,
    RESET_DEALER_CARS,
    UPDATE_DEALER_CARS,
    UPDATE_PAGE_NUMBER,
    UPDATE_PAGE_LIMIT,
    SEARCH_TERM_ADDED
} from './Constants';

/** Initial state for the dealer cars reducer */
const initialState = {
    dealerCarsData: [],    // Array to hold dealer cars data
    loading: true,         // Indicates if data is being loaded
    totalResults: 0,       // Total number of results (for pagination)
    currentPage: 1,        // Current page number (for pagination)
    limit: 25,             // Limit of results per page (for pagination)
}

/** Reducer function for managing dealer cars-related state */
export default (state = initialState, action) => {
    switch (action.type) {
        /** Update state with fetched dealer cars data */
        case FETCH_DEALER_CARS:
            return {
                ...state,
                ...action.data,  // Spread operator to merge action data with existing state
                loading: false, // Set loading to false as data has been fetched
            }
        /** Update state with new current page number */
        case UPDATE_PAGE_NUMBER:
            return {
                ...state,
                currentPage: action.data,  // Set current page to the value from action
                loading: true,             // Set loading to true as data is being updated
            }
        /** Update state with new limit for results per page */
        case UPDATE_PAGE_LIMIT:
            return {
                ...state,
                limit: action.data,  // Set limit to the value from action
                loading: true,       // Set loading to true as data is being updated
            }
        /** Update specific dealer car data in state */
        case UPDATE_DEALER_CARS:
            return {
                ...state,
                dealerCarsData: state.dealerCarsData.map(x => {
                    if (x._id === action.data._id) {
                        return action.data; // Replace existing dealer car data with updated data
                    }
                    return x; // Keep other dealer car data unchanged
                })
            }
        /** Reset state to initial values when search term is added */
        case SEARCH_TERM_ADDED:
            return {
                ...state,
                loading: true,    // Set loading to true as data is being updated
                currentPage: 1,   // Reset current page to first page
                limit: 25         // Reset limit to default value
            }
        /** Reset state to initial values */
        case RESET_DEALER_CARS:
            return {
                ...state,
                dealerCarsData: [],  // Clear dealer cars data
                loading: true,       // Set loading to true as data is being updated
                totalResults: 0,     // Reset total results to 0
                currentPage: 1,      // Reset current page to first page
                limit: 25            // Reset limit to default value
            }
        default:
            return state;  // Return current state if action type doesn't match
    }
}