import React, { useEffect, useState } from 'react'
// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/Dealers/Actions';
import { setHeaderNameAction } from '../../redux/MainLayout/Actions';
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import TablePaginationActions from './TablePaginationActions'
import Loader from '../../components/Loader'
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
    },
    rootPaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    link: {
        textDecoration: 'none', // Remove underline from link
        color: 'inherit', // Inherit text color
      },
}))

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'gsm', label: 'Cell', minWidth: 150,align: 'center'},
    { id: 'email', label: 'Email', minWidth: 190,align: 'center' },
    { id: 'city', label: 'City', minWidth: 190 ,align: 'center'},
    { id: 'total_cars', label: 'Total Cars', minWidth: 140,align: 'center' },
    { id: 'total_reviews', label: 'Total Reviews', minWidth: 140,align: 'center' },
]

const Dealers = (props) => {
    const {
        limit,
        currentPage,
        totalResults,
        loading,
        dealersData,
        fetchAllDealersAction,
        resetDealersDataAction,
        changePageNumberAction,
        changePageLimitAction,
        setHeaderNameAction,
        searchTermAddedAction,
    } = props
    const classes = useStyles()
    const [selectedDealerIndex, setselectedDealerIndex] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')

    /** Set header name and reset dealer  data on component mount */
    useEffect(() => {
        setHeaderNameAction('Dealers');
        return () => {
            resetDealersDataAction();
        }
    }, [setHeaderNameAction, resetDealersDataAction]);

    /** Fetch dealer  data when limit, currentPage, loading, or searchTerm changes*/
    useEffect(() => {
        if (loading) {
            const payload = {
                page: currentPage,
                limit,
                search: searchTerm,
            }
            fetchAllDealersAction(payload)
        }
    }, [limit, currentPage, loading, fetchAllDealersAction, searchTerm])

    /** Calculate the number of empty rows for pagination*/ 
    const emptyRows =
        limit - Math.min(limit, dealersData?.length - currentPage * limit)

    /**Handle page change */
    const handleChangePage = (event, newPage) => {
        changePageNumberAction(newPage + 1)
    }
    /**Handle rows per page change */
    const handleChangeLimit = (event) => {
        changePageLimitAction(parseInt(event.target.value, 10))
    }


    let dealerrData = undefined
    if (selectedDealerIndex !== null) {
        dealerrData = { ...dealerrData[selectedDealerIndex] }
    }
    /**Handle search form submission */
    const handleSearchSubmit = (e) => {
        e && e.preventDefault()
        searchTermAddedAction()
    }

    return (
        <React.Fragment>
            <div className="d-flex justify-content-end mb-3">
                <Paper
                    component="form"
                    className={classes.rootPaper}
                    onSubmit={handleSearchSubmit}
                >
                    <InputBase
                        className={classes.input}
                        placeholder="Search Dealer"
                        inputProps={{ 'aria-label': 'search dealer' }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <IconButton
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={handleSearchSubmit}
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <React.Fragment>
                    <TableContainer
                        component={Paper}
                    // style={{ height: 'calc(100vh - 180px)' }}
                    >
                        <Table
                            stickyHeader
                            className={classes.table}
                            aria-label="custom pagination table"
                        >
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dealersData?.length > 0 ? (
                                    dealersData.map((dealer, index) => (
                                        <TableRow key={dealer._id} component={Link} to={`/dealerId/${dealer._id}`} className={classes.link}>
                                            <TableCell component="th" scope="row">
                                                {dealer.fullname}
                                            </TableCell>
                                            <TableCell align="center">{dealer.gsm}</TableCell>
                                            <TableCell align="center">{dealer.email}</TableCell>
                                            <TableCell align="center">{dealer.city}</TableCell>
                                            <TableCell align="center">{dealer.total_cars}</TableCell>
                                            <TableCell align="center">{dealer.total_reviews}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow style={{ height: 2 * emptyRows }}>
                                    <TableCell colSpan={6} >
                                      <h1>  No Data Avaiable</h1>
                                        </TableCell>
                                </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={6}
                                        count={totalResults}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                            defaultValue: limit,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeLimit}
                                        ActionsComponent={TablePaginationActions}
                                        rowsPerPage={limit}
                                        page={currentPage - 1}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                  
                </React.Fragment>
            )}
        </React.Fragment>

    )
}


const mapStateToProps = (state) => ({
    ...state.Dealers
})

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dealers)


