import { FETCH_TECHNICIAN, RESET_TECHNICIAN, UPDATE_TECHNICIAN, UPDATE_PAGE_NUMBER, UPDATE_PAGE_LIMIT, SEARCH_TERM_ADDED } from './Constants';
import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from './../../helpers/ApiHandler';

const api = new Api();

export const fetchAllTechniciansAction = (payload) => (dispatch) => {
    api.post(`admin_master/get-technician-list`, { data: payload })
        .then((result) => {
            if (result.success) {
                dispatch({
                    type: FETCH_TECHNICIAN,
                    data: {
                        techniciansData: result.data,
                        totalResults: result.totalCount,
                    },
                });
            } else {
                dispatch({
                    type: FETCH_TECHNICIAN,
                    data: {
                        techniciansData: [],
                        totalResults: 0,
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err);
        });
};

export const updateTechnicianStatusAction = (payload) => (dispatch) => {
    api.put(`admin_master/update-technician-details`, { data: payload })
        .then((result) => {
            if (result.success) {
                dispatch({
                    type: UPDATE_TECHNICIAN,
                    data: result.data,
                });
                dispatch({
                    type: ADDED_NOTIFICATION,
                    data: {
                        type: "success",
                        message: "Technician status updated successfully",
                    },
                });
            } else {
                dispatch({
                    type: ADDED_NOTIFICATION,
                    data: {
                        type: "error",
                        message: "Something went wrong",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err);
        });
};

export const resetTechniciansDataAction = () => (dispatch) => {
    dispatch({
        type: RESET_TECHNICIAN,
    });
};

export const changePageNumberAction = (pageNumber) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_NUMBER,
        data: pageNumber,
    });
};

export const changePageLimitAction = (limit) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_LIMIT,
        data: limit,
    });
};

export const searchTermAddedAction = () => (dispatch) => {
    dispatch({
        type: SEARCH_TERM_ADDED,
    });
};
