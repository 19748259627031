import {
  GET_ALL_MAKES,
  GET_MODELS,
  ADD_MAKE,
  ADD_MODEL,
  RESET_MAKE_MODEL,
} from './Constants';

const initialState = {
  allMakes: [],
  allModels: [],
  isLoading: true,
  message: '',
  hasError: false,
  hasSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MAKES:
      return {
        ...state,
        allMakes: action.data
      }
    case GET_MODELS:
      return {
        ...state,
        allModels: action.data
      }
    case ADD_MAKE:
      return {
        ...state,
        allMakes: [
          ...state.allMakes,
          action.data
        ]
      }
    case ADD_MODEL:
      return {
        ...state,
        allModels: action.data
      }
    case RESET_MAKE_MODEL:
      return {
        allMakes: [],
        allModels: [],
        isLoading: false,
        message: '',
        hasError: false,
        hasSuccess: false,
      }
    default:
      return state;
  }
}