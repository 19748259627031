import {
  FETCH_CONTACT_DETAILS,
  RESET_CONTACT_DETAILS,
  UPDATE_PAGE_NUMBER,
  UPDATE_PAGE_LIMIT,
  DELETE_CONTACT_DETAILS,
} from './Constants';

const initialState = {
  contactDetailData: [],
  loading: true,
  totalResults: 0,
  currentPage: 1,
  limit: 25,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACT_DETAILS:
      return {
        ...state,
        ...action.data,
        loading: false,
      }
    case UPDATE_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.data,
        loading: true,
      }
    case UPDATE_PAGE_LIMIT:
      return {
        ...state,
        limit: action.data,
        loading: true,
      }
    case DELETE_CONTACT_DETAILS:
      return {
        ...state,
        contactDetailData: state.contactDetailData.filter(x => x._id !== action.data),
        totalResults: state.totalResults - 1
      }
    case RESET_CONTACT_DETAILS:
      return {
        ...state,
        contactDetailData: [],
        loading: true,
        totalResults: 0,
        currentPage: 1,
        limit: 25,
      }
    default:
      return state
  }
}