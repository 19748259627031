import { FETCH_REPORTEDSTOLENCARS, RESET_REPORTEDSTOLENCARS, UPDATE_PAGE_NUMBER, UPDATE_PAGE_LIMIT, SEARCH_TERM_ADDED, UPDATE_REPORTEDSTOLENCARS, UPDATE_NOTIFICATIONLOADING, DELETE_REPORTEDSTOLENCARS } from './Constants';
import Api from '../../helpers/ApiHandler';
import { ADDED_NOTIFICATION } from "./../Notification/Constants";


const api = new Api();

export const fetchAllReportedStolenCarsAction = (payload) => (dispatch) => {
    api.post(`admin_master/get-reported-stolen-cars-list`, { data: payload })
        .then((result) => {
            if (result.success) {
                dispatch({
                    type: FETCH_REPORTEDSTOLENCARS,
                    data: {
                        ReportedStolenCarsData: result.data,
                        totalResults: result.totalCount,
                    },
                });
            } else {
                dispatch({
                    type: FETCH_REPORTEDSTOLENCARS,
                    data: {
                        ReportedStolenCarsData: [],
                        totalResults: 0,
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err);
        });
};

export const resetReportedStolenCarsDataAction = () => (dispatch) => {
    dispatch({
        type: RESET_REPORTEDSTOLENCARS,
    });
};

export const changePageNumberAction = (pageNumber) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_NUMBER,
        data: pageNumber,
    });
};

export const changePageLimitAction = (limit) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_LIMIT,
        data: limit,
    });
};

export const searchTermAddedAction = () => (dispatch) => {
    dispatch({
        type: SEARCH_TERM_ADDED,
    });
};

export const updateReportedStolenCarsAction = (payload) => (dispatch) => {
    dispatch({
        type: UPDATE_NOTIFICATIONLOADING,
        data: true
    })
    api.put(`admin_master/update-reported-stolen-cars-details`, { data: payload })
        .then((result) => {
            if (result.success) {
                dispatch({
                    type: UPDATE_REPORTEDSTOLENCARS,
                    data: result.data,
                });
                dispatch({
                    type: ADDED_NOTIFICATION,
                    data: {
                        type: "success",
                        message: "ReportedStolenCars status updated successfully",
                    },
                });
            } else {
                dispatch({
                    type: ADDED_NOTIFICATION,
                    data: {
                        type: "error",
                        message: "Something went wrong",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err);
        });
};


export const deleteReportedStolenCar = (payload) => (dispatch) => {
    api.delete(`admin_master/remove-reported-stolen-car`, { data: payload })
        .then((result) => {
            if (result.success) {
                dispatch({
                    type: DELETE_REPORTEDSTOLENCARS,
                    data: result.data,
                });
                dispatch({
                    type: ADDED_NOTIFICATION,
                    data: {
                        type: "success",
                        message: "ReportedStolenCars deleted successfully",
                    },
                });
            } else {
                dispatch({
                    type: ADDED_NOTIFICATION,
                    data: {
                        type: "error",
                        message: "Something went wrong",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err);
        });
};