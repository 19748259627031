import React, { useEffect } from 'react'

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/CarEdit/Actions';
import { setHeaderNameAction } from './../../redux/MainLayout/Actions';

// Document Head Manager
import { Helmet } from "react-helmet";

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { DropzoneArea } from 'material-ui-dropzone'


import './CarEdit.css';
import ProfileImageUpload from './../../components/Profile/ProfileImageUpload';

export const YellowCheckbox = withStyles({
  root: {
    color: '#f5c82a',
    '&$checked': {
      color: '#f5c82a',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
}));

const CarEditValidationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a title'),
  condition: Yup.string().required('Please select a condition'),
  year: Yup.string().required('Please select a year'),
  make: Yup.object().required('Please select a make'),
  model: Yup.object().required('Please select a model'),
  bodytype: Yup.string().required('Please select a body type'),
  mileage: Yup.string().required('Please enter mileage'),
  fueltype: Yup.string().required('Please select a fuel type'),
  engine: Yup.string().required('Please enter a engine litre'),
  transmission: Yup.string().required('Please select a transmission'),
  drive: Yup.string().required('Please select a drive'),
  exteriorcolor: Yup.string().required('Please select a exterior color'),
  interiorcolor: Yup.string().required('Please select a interior color'),
  address: Yup.string().required('Please enter a address'),
  carfeatures: Yup.array().min(1, 'Please select atleast one car feature'),
  description: Yup.string().required('Please enter a description'),
  price: Yup.string().required('Please enter a price'),
  images: Yup.array().min(1, 'Please upload atleast 1 image'),
  featured_image: Yup.mixed().required('Please select a featured image'),
});

const CarEditValidationSchemaUpdate = Yup.object().shape({
  title: Yup.string().required('Please enter a title'),
  condition: Yup.string().required('Please select a condition'),
  year: Yup.string().required('Please select a year'),
  make: Yup.object().required('Please select a make'),
  model: Yup.object().required('Please select a model'),
  bodytype: Yup.string().required('Please select a body type'),
  mileage: Yup.string().required('Please enter mileage'),
  fueltype: Yup.string().required('Please select a fuel type'),
  engine: Yup.string().required('Please enter a engine litre'),
  transmission: Yup.string().required('Please select a transmission'),
  drive: Yup.string().required('Please select a drive'),
  exteriorcolor: Yup.string().required('Please select a exterior color'),
  interiorcolor: Yup.string().required('Please select a interior color'),
  address: Yup.string().required('Please enter a address'),
  carfeatures: Yup.array().min(1, 'Please select atleast one car feature'),
  description: Yup.string().required('Please enter a description'),
  price: Yup.string().required('Please enter a price'),
});

const currentYear = (new Date()).getFullYear();
const years = [...new Array(15)].map((value, index) => (currentYear + index - 14).toString());

const bodyType = [
  'Convertible',
  'Coupe',
  'Sedan',
  'Van',
  'Truck',
  'Hatchback',
  'SUV',
];

const exteriorcolors = ['Ash Gold', 'Blue', 'Bottle Green', 'Brown', 'Deep Blue Pearl', 'Dolphin Grey', 'Flame Red', 'Green', 'Grey', 'Maroon', 'Midnight Silver Metallic', 'Navy', 'Obsidian Black Metallic', 'Orange', 'Pearl White', 'Red', 'Red Multi-Coat', 'Silver Metallic', 'Solid Black', 'Solid White', 'Titanium Metallic', 'Turqoise', 'Turquoise', 'Yellow']
const interiorcolors = ['Beige', 'Black', 'Blue', 'Brown', 'Gold', 'Green', 'Grey', 'Jet-Black', 'Lime', 'Magenta', 'Maroon', 'Multi-pattern', 'Navy Blue', 'Orange', 'Pink', 'Purple', 'Red', 'Silver', 'Teal', 'White', 'Yellow'];
const carfeatures = ['A/C: Front', 'A/C: Rear', 'Backup Camera', 'Cruise Control', 'Navigation', 'Power Locks', 'Power Steering', 'AM/FM Stereo', 'CD Player', 'DVD System', 'MP3 Player', 'Portable Audio', 'Premium Audio', 'Airbag: Driver', 'Airbag: Passenger', 'Antilock Brakes', 'Bluetooth', 'Hands-Free', 'Fog Lights', 'Security System', 'Bucket Seats', 'Heated Seats', 'Leather Interior Seats', 'Memory Seats', 'Power Seats', 'Third Row Seats', 'Power Windows', 'Windows Defroster', 'Rear Window', 'Wiper Tinted Glass', 'Alloy Wheels', 'Sunroof', 'Tow Package', 'Trailer Hitch', 'Warranty'];

const CarEdit = (props) => {
  const {
    isLoading,
    message,
    hasError,
    hasSuccess,
    editCarData,
    vehicleBrandsList,
    vehicleModelsList,
    fetchVehicleBrandAction,
    fetchVehicleModelAction,
    resetAllCarEditOptionAction,
    removeModelsAction,
    sendNotificationAction,
    setLoadingAction,
    addCarAction,
    fetchCarDataAction,
    deleteCarImageAction,
    updateCarDataAction,
    setHeaderNameAction,
    match: { params }
  } = props;
  const classes = useStyles();

  const handleFormSubmit = values => {
    setLoadingAction();

    let newValues = {
      ...values,
      make: values.make.maketitle,
      model: values.model.modeltitle,
    }

    const formData = new FormData();

    if(params.id) {
      const car_id = editCarData._id;
      newValues = {
        ...newValues,
        car_id
      }

      Object.keys(newValues).forEach(key => {
        if(key === 'images') {
          if(newValues[key].length > 0) {
            for(let i = 0; i < newValues[key].length; i++) {
              formData.append('images', newValues[key][i])
            }
          }
        } else if (key === 'featured_image') {
          if(newValues[key]) {
            formData.append(key, newValues[key])
          }
        } else {
          formData.append(key, newValues[key])
        }
      })

      updateCarDataAction(formData);
    } else {
      Object.keys(newValues).forEach(key => {
        if(key === 'images') {
          for(let i = 0; i < newValues[key].length; i++) {
            formData.append('images', newValues[key][i])
          }
        } else {
          formData.append(key, newValues[key])
        }
      })

      addCarAction(formData);
    }
  }

  const onChangeBrand = brand => {
    fetchVehicleModelAction(brand)
  }

  useEffect(() => {
    if(editCarData && vehicleBrandsList.length > 0 && vehicleModelsList.length === 0) {
      const brand = vehicleBrandsList.find(x => x.maketitle === editCarData.make);
      fetchVehicleModelAction(brand);
    }
  }, [editCarData, vehicleBrandsList, vehicleModelsList, fetchVehicleModelAction])

  useEffect(() => {
    fetchVehicleBrandAction();
    setHeaderNameAction('Edit Car');
    if(params.id) {
      fetchCarDataAction(params.id)
    }
    return () => {
      resetAllCarEditOptionAction();
    };
  }, [fetchVehicleBrandAction, setHeaderNameAction, resetAllCarEditOptionAction, fetchCarDataAction, params])

  const handleDeleteImage = (image_url) => {
    const payload = {
      image_url,
      car_id: editCarData._id
    }
    deleteCarImageAction(payload);
  }

  const isCarDataLoading = params.id && !editCarData;
  return (
      <div className={`${classes.root} mx-auto`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Edit Ad | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <div className="post-ad__form-block shadow">
          {isLoading || isCarDataLoading ?
            <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
              <CircularProgress />
            </Container>
            :
            hasError ?
              <React.Fragment>
                <Alert className="mt-4" style={{ width: '100%' }} severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {message}
                </Alert>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  component={Link}
                  to="/post/ad"
                >
                  Refresh
                </Button>
              </React.Fragment>
              :
              hasSuccess ?
                <React.Fragment>
                  <Alert className="mt-4" style={{ width: '100%' }} severity="success">
                    <AlertTitle>{message}</AlertTitle>
                    <p>
                      You can now check it under profile page.
                    </p>
                  </Alert>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="default"
                    className={classes.submit}
                    component={Link}
                    to={'/listings'}
                  >
                    Back to Listing Page
                  </Button>
                </React.Fragment>
                :
                <Formik
                  enableReinitialize
                  initialValues={{
                    title: editCarData ? editCarData.title : '',
                    condition: editCarData ? editCarData.condition : '',
                    year: editCarData ? editCarData.year : '',
                    make: editCarData ? { maketitle: editCarData.make } : null,
                    model: editCarData ? { modeltitle: editCarData.model } : null,
                    bodytype: editCarData ? editCarData.bodytype : '',
                    mileage: editCarData ? editCarData.mileage : '',
                    fueltype: editCarData ? editCarData.fueltype : '',
                    engine: editCarData ? editCarData.engine : '',
                    transmission: editCarData ? editCarData.transmission : '',
                    drive: editCarData ? editCarData.drive : '',
                    exteriorcolor: editCarData ? editCarData.exteriorcolor : '',
                    interiorcolor: editCarData ? editCarData.interiorcolor : '',
                    address: editCarData ? editCarData.address : '',
                    carfeatures: editCarData ? editCarData.carfeatures : [],
                    description: editCarData ? editCarData.description : '',
                    price: editCarData ? editCarData.price : '',
                    images: [],
                    featured_image: undefined,
                  }}
                  onSubmit={handleFormSubmit}
                  validationSchema={params.id ? CarEditValidationSchemaUpdate : CarEditValidationSchema}
                  validateOnChange={true}
                >
                  {(renderProps) => {
                    const { values: formValues, touched, errors } = renderProps;
                    return (
                      <Form className={classes.form}>
                        <TextField
                          variant="outlined"
                          className="news-letter-input"
                          label="Car Title"
                          style={{ width: '100%' }}
                          margin="normal"
                          fullWidth
                          id="title"
                          name="title"
                          autoFocus
                          newsletter={true}
                          value={formValues.title}
                          onChange={e => renderProps.setFieldValue('title', e.target.value)}
                          helperText={touched.title && errors.title}
                          error={touched.title && errors.title ? true : false}
                        />
                        <div className="post-ad__multiple-fields">
                          <Autocomplete
                            options={['Used', 'New']}
                            getOptionLabel={option => option}
                            autoComplete
                            includeInputInList
                            renderInput={params =>
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Condition"
                                className="news-letter-input mt-0"
                                margin="normal"
                                fullWidth
                                newsletter={true}
                                helperText={touched.condition && errors.condition}
                                error={touched.condition && errors.condition ? true : false}
                              />
                            }
                            value={formValues.condition === '' ? null : formValues.condition}
                            onChange={(event, value) => renderProps.setFieldValue('condition', value)}
                          />
                          <Autocomplete
                            options={years}
                            getOptionLabel={option => option.toString()}
                            autoComplete
                            includeInputInList
                            renderInput={params =>
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Year"
                                className="news-letter-input mt-0"
                                margin="normal"
                                fullWidth
                                newsletter={true}
                                helperText={touched.year && errors.year}
                                error={touched.year && errors.year ? true : false}
                              />
                            }
                            value={formValues.year === '' ? null : formValues.year}
                            onChange={(event, value) => renderProps.setFieldValue('year', value)}
                          />
                        </div>
                        <div className="post-ad__multiple-fields">
                          <Autocomplete
                            options={vehicleBrandsList}
                            getOptionLabel={option => option.maketitle}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Make"
                                fullWidth
                                label="Make"
                                className="news-letter-input mt-0"
                                margin="normal"
                                newsletter={true}
                                helperText={touched.make && errors.make}
                                error={touched.make && errors.make ? true : false}
                              />
                            )}
                            value={formValues.make}
                            onChange={(event, value) => {
                              if (value) {
                                onChangeBrand(value);
                              } else {
                                removeModelsAction();
                              }
                              renderProps.setFieldValue('make', value);
                              renderProps.setFieldValue('model', null);
                            }}
                          />
                          <Autocomplete
                            options={vehicleModelsList}
                            getOptionLabel={option => option.modeltitle}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Model"
                                fullWidth
                                label="Model"
                                className="news-letter-input mt-0"
                                margin="normal"
                                newsletter={true}
                                helperText={touched.model && errors.model}
                                error={touched.model && errors.model ? true : false}
                              />
                            )}
                            value={formValues.model}
                            onChange={(event, value) => renderProps.setFieldValue('model', value)}
                          />
                        </div>
                        <div className="post-ad__multiple-fields">
                          <Autocomplete
                            options={bodyType}
                            getOptionLabel={option => option}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Body Type"
                                fullWidth
                                label="Body Type"
                                className="news-letter-input mt-0"
                                margin="normal"
                                newsletter={true}
                                helperText={touched.bodytype && errors.bodytype}
                                error={touched.bodytype && errors.bodytype ? true : false}
                              />
                            )}
                            value={formValues.bodytype}
                            onChange={(event, value) => renderProps.setFieldValue('bodytype', value)}
                          />
                          <TextField
                            variant="outlined"
                            className="news-letter-input mt-0"
                            label="Mileage"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            name="mileage"
                            newsletter={true}
                            value={formValues.mileage}
                            onChange={e => renderProps.setFieldValue('mileage', e.target.value)}
                            helperText={touched.mileage && errors.mileage}
                            error={touched.mileage && errors.mileage ? true : false}
                          />
                        </div>
                        <div className="post-ad__multiple-fields">
                          <Autocomplete
                            options={['Diesel', 'Electric', 'Ethanol', 'Petrol']}
                            getOptionLabel={option => option}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Fuel Type"
                                fullWidth
                                label="Fuel Type"
                                className="news-letter-input mt-0"
                                margin="normal"
                                newsletter={true}
                                helperText={touched.fueltype && errors.fueltype}
                                error={touched.fueltype && errors.fueltype ? true : false}
                              />
                            )}
                            value={formValues.fueltype}
                            onChange={(event, value) => renderProps.setFieldValue('fueltype', value)}
                          />
                          <TextField
                            variant="outlined"
                            className="news-letter-input mt-0"
                            label="Engine"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            id="engine"
                            name="engine"
                            newsletter={true}
                            value={formValues.engine}
                            onChange={e => renderProps.setFieldValue('engine', e.target.value)}
                            helperText={touched.engine && errors.engine}
                            error={touched.engine && errors.engine ? true : false}
                          />
                        </div>
                        <div className="post-ad__multiple-fields">
                          <Autocomplete
                            options={['Automatic', 'Manual', 'Semi-Autometic']}
                            getOptionLabel={option => option}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Transmission"
                                fullWidth
                                label="Transmission"
                                className="news-letter-input mt-0"
                                margin="normal"
                                newsletter={true}
                                helperText={touched.transmission && errors.transmission}
                                error={touched.transmission && errors.transmission ? true : false}
                              />
                            )}
                            value={formValues.transmission}
                            onChange={(event, value) => renderProps.setFieldValue('transmission', value)}
                          />
                          <Autocomplete
                            options={['4WD', 'AWD', 'FWD', 'RWD']}
                            getOptionLabel={option => option}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Drive"
                                fullWidth
                                label="Drive"
                                className="news-letter-input mt-0"
                                margin="normal"
                                newsletter={true}
                                helperText={touched.drive && errors.drive}
                                error={touched.drive && errors.drive ? true : false}
                              />
                            )}
                            value={formValues.drive}
                            onChange={(event, value) => renderProps.setFieldValue('drive', value)}
                          />
                        </div>
                        <div className="post-ad__multiple-fields">
                          <Autocomplete
                            options={exteriorcolors}
                            getOptionLabel={option => option}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Exterior Color"
                                fullWidth
                                label="Exterior Color"
                                className="news-letter-input mt-0"
                                margin="normal"
                                newsletter={true}
                                helperText={touched.exteriorcolor && errors.exteriorcolor}
                                error={touched.exteriorcolor && errors.exteriorcolor ? true : false}
                              />
                            )}
                            value={formValues.exteriorcolor}
                            onChange={(event, value) => renderProps.setFieldValue('exteriorcolor', value)}
                          />
                          <Autocomplete
                            options={interiorcolors}
                            getOptionLabel={option => option}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Interior Color"
                                fullWidth
                                label="Interior Color"
                                className="news-letter-input mt-0"
                                margin="normal"
                                newsletter={true}
                                helperText={touched.interiorcolor && errors.interiorcolor}
                                error={touched.interiorcolor && errors.interiorcolor ? true : false}
                              />
                            )}
                            value={formValues.interiorcolor}
                            onChange={(event, value) => renderProps.setFieldValue('interiorcolor', value)}
                          />
                        </div>
                        <TextField
                          variant="outlined"
                          className="news-letter-input"
                          label="Zip Code or Address"
                          style={{ width: '100%' }}
                          margin="normal"
                          fullWidth
                          name="address"
                          newsletter={true}
                          value={formValues.address}
                          onChange={e => renderProps.setFieldValue('address', e.target.value)}
                          helperText={touched.address && errors.address}
                          error={touched.address && errors.address ? true : false}
                        />
                        <TextField
                          variant="outlined"
                          className="news-letter-input"
                          label="Price"
                          style={{ width: '100%' }}
                          margin="normal"
                          fullWidth
                          name="price"
                          newsletter={true}
                          InputProps={{
                            type: 'number',
                          }}
                          value={formValues.price}
                          onChange={e => renderProps.setFieldValue('price', e.target.value)}
                          helperText={touched.price && errors.price}
                          error={touched.price && errors.price ? true : false}
                        />
                        <TextField
                          variant="outlined"
                          className="news-letter-input"
                          label="Ad Description"
                          style={{ width: '100%' }}
                          margin="normal"
                          fullWidth
                          id="description"
                          name="description"
                          multiline
                          rowsMax="5"
                          rows="5"
                          newsletter={true}
                          value={formValues.description}
                          onChange={e => renderProps.setFieldValue('description', e.target.value)}
                          helperText={touched.description && errors.description}
                          error={touched.description && errors.description ? true : false}
                        />
                        <div className="mt-3">
                          <FormLabel className="font-size-14 mb-2" component="legend">Featured Image {params.id ? '(Uploading featured image will replace old image)' : ''}</FormLabel>
                          <div className="d-flex flex-wrap">
                            {carfeatures.map(feature => {
                              return (
                                <FormControlLabel
                                  key={feature}
                                  className="d-block car-feature-checkbox"
                                  control={
                                    <YellowCheckbox
                                      checked={formValues.carfeatures.includes(feature)}
                                      onChange={(e) => renderProps.setFieldValue('carfeatures', formValues.carfeatures.includes(feature) ? formValues.carfeatures.filter(x => x !== feature) : [...formValues.carfeatures, feature])}
                                    />
                                  }
                                  label={feature}
                                />
                              )
                            })}
                          </div>
                          <ErrorMessage className="text-error font-size-12 mt-2" name="carfeatures" component="div" />
                        </div>
                        <div className="mt-3">
                          <FormLabel className="font-size-14 mb-2" component="legend">Featured Image</FormLabel>
                          <ProfileImageUpload
                            className="business-profile"
                            uploadFile={(file) => renderProps.setFieldValue('featured_image', file)}
                            receiveError={(err) => sendNotificationAction('error', err)}
                            // needValidation
                          />
                          <ErrorMessage className="text-error font-size-12 mt-2" name="featured_image" component="div" />
                        </div>
                        {editCarData &&
                          <div className="mt-3 delete-images">
                            {editCarData.images.map(image => {
                              return (
                                <div key={image} className="position-relative delete-image-div">
                                  <img className="object-fit-cover" src={image} width={100} height={100} alt="" />
                                  <IconButton onClick={() => handleDeleteImage(image)} aria-label="delete" className="delete-image-button">
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              )
                            })}
                          </div>
                        }
                        <DropzoneArea
                          dropzoneClass="mt-3"
                          onChange={(files) => renderProps.setFieldValue('images', files)}
                          maxFileSize={30000000}
                          filesLimit={10}
                          acceptedFiles={['image/jpeg', 'image/jpg', 'image/png', 'image/webp']}
                        />
                        <ErrorMessage className="text-error font-size-12 mt-2" name="images" component="div" />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="default"
                          className={classes.submit}
                        >
                          {params.id ? 'Update My Ad' : 'Publish My Ad'}
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
          }
        </div>
      </div>
  )
}

const mapStateToProps = (state) => ({
  ...state.CarEdit
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarEdit)
