import {
    FETCH_REPORTEDSTOLENCARS,
    RESET_REPORTEDSTOLENCARS,
    UPDATE_REPORTEDSTOLENCARS,
    UPDATE_PAGE_NUMBER,
    UPDATE_PAGE_LIMIT,
    SEARCH_TERM_ADDED,
    UPDATE_NOTIFICATIONLOADING,
    DELETE_REPORTEDSTOLENCARS
} from './Constants';

const initialState = {
    ReportedStolenCarsData: [],
    loading: true,
    totalResults: 0,
    currentPage: 1,
    limit: 25,
    notificationLoading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORTEDSTOLENCARS:
            return {
                ...state,
                ...action.data,
                loading: false,
            }
        case UPDATE_PAGE_NUMBER:
            return {
                ...state,
                currentPage: action.data,
                loading: true,
            }
        case UPDATE_PAGE_LIMIT:
            return {
                ...state,
                limit: action.data,
                loading: true,
            }
        case UPDATE_REPORTEDSTOLENCARS:
            return {
                ...state,
                ReportedStolenCarsData: state.ReportedStolenCarsData.map(x => {
                    if (x._id === action.data._id) {
                        return action.data
                    }

                    return x;
                }),
                notificationLoading: false,
            }
        case SEARCH_TERM_ADDED:
            return {
                ...state,
                loading: true,
                currentPage: 1,
                limit: 25
            }
        case RESET_REPORTEDSTOLENCARS:
            return {
                ...state,
                ReportedStolenCarsData: [],
                loading: true,
                totalResults: 0,
                currentPage: 1,
                limit: 25,
            }

        case UPDATE_NOTIFICATIONLOADING:
            return {
                ...state,
                notificationLoading: action.data
            }


        case DELETE_REPORTEDSTOLENCARS:
            let index = state.ReportedStolenCarsData.findIndex(v => v._id === action.data);
            let newtotalResults = state.totalResults
            if (index !== -1) {
                let newChatReportedUsersData = [...state.ReportedStolenCarsData];
                newChatReportedUsersData.splice(index, 1);
                return {
                    ...state,
                    ReportedStolenCarsData: newChatReportedUsersData,
                    totalResults: newtotalResults - 1
                };
            }
            return state;

        default:
            return state
    }
}