import React from 'react';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Login/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

// Document Head Manager
import { Helmet } from "react-helmet";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';

import { Copyright } from './../../components/MainLayout';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${process.env.REACT_APP_BASE_PATH}/media/background.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter username'),
  password: Yup.string().required('Please enter a password'),
});

const Login = (props) => {
  const { isAuthenticated, hasError, errorMessage } = props;
  const classes = useStyles();

  const handleFormSubmit = (values) => {
    props.handleLoginRequestAction(values);
  }

  return (
    isAuthenticated ?
      <Redirect to="/" />
      :
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
            </Typography>
              {hasError &&
                <Alert className="mt-3 w-100" severity="error">{errorMessage}</Alert>
              }
              <Formik
                enableReinitialize
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={handleFormSubmit}
                validationSchema={LoginValidationSchema}
                validateOnChange={false}
              >
                {(renderProps) => {
                  const { values: formValues } = renderProps;
                  return (
                    <Form className={classes.form}>
                      <TextField
                        label="Your Username"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        id="username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={formValues.email}
                        onChange={e => renderProps.setFieldValue('email', e.target.value)}
                        helperText={renderProps.errors.email || ''}
                        error={renderProps.errors.email ? true : false}
                      />
                      <TextField
                        label="Password"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        id="password"
                        name="password"
                        autoComplete="current-password"
                        InputProps={{
                          type: 'password',
                        }}
                        value={formValues.password}
                        onChange={e => renderProps.setFieldValue('password', e.target.value)}
                        helperText={renderProps.errors.password || ''}
                        error={renderProps.errors.password ? true : false}
                      />
                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Sign In
                      </Button>
                      {/* <Grid container>
                      <Grid item xs>
                        <Link to="/forgot-password">
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link to="/register">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid> */}
                      <Box mt={5}>
                        <Copyright />
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  ...state.Login
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

