import { GET_ALL_STATISTICS, RESET_DASHBOARD } from './Constants';

import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from './../../helpers/ApiHandler';
const api = new Api();

export const getAllStatisticsDataAction = () => dispatch => {
  api.get(`admin_master/dashboard/get-dashboard-count`)
    .then(result => {
      if (result.success) {
        dispatch({
          type: GET_ALL_STATISTICS,
          data: result.data
        })
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: 'Something went wrong'
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const resetDashboardAction = () => dispatch => {
  dispatch({
    type: RESET_DASHBOARD
  })
}