import React, { useEffect, useState } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/MakeModel/Actions';
import { setHeaderNameAction } from './../../redux/MainLayout/Actions';
import { sendNotificationAction } from './../../redux/Notification/Actions';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1070,
  },
  rootPaper: {
    padding: '2px 4px',
    borderRadius: 0,
  },
  rootInputWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const MakeModel = (props) => {
  const {
    // isLoading,
    allMakes,
    allModels,
    fetchAllMakesAction,
    fetchAllModelsAction,
    resetMakeModelOptionsAction,
    setHeaderNameAction,
    sendNotificationAction,
    addMakeAction,
    addModelAction,
  } = props;

  const [makeName, setMakeName] = useState('');
  const [selectedMake, setSelectedMake] = useState(null);
  const [modelName, setModelName] = useState('');

  const classes = useStyles();

  useEffect(() => {
    setHeaderNameAction('Make/Model');
    fetchAllMakesAction();
    return () => {
      resetMakeModelOptionsAction();
    };
  }, [resetMakeModelOptionsAction, setHeaderNameAction, fetchAllMakesAction])

  const handleChangeMake = id => {
    fetchAllModelsAction(id);
  }

  const handleSaveMake = e => {
    e && e.preventDefault();

    if (makeName === '') {
      sendNotificationAction('error', 'Please add make name')
    } else {
      const payload = { maketitle: makeName };
      addMakeAction(payload);
      setMakeName('');
    }
  }

  const handleSaveModel = e => {
    e && e.preventDefault();

    if (!selectedMake || modelName === '') {
      sendNotificationAction('error', 'Please select make and add model name')
    } else {
      const payload = { model: modelName, modelmake: selectedMake.maketitle };
      addModelAction(payload);
      setModelName('');
    }
  }

  return (
    <div className="d-flex" style={{ height: 'calc(100vh - 180px)' }}>
      <div className="shadow flex-1">
        <Paper component="form" className={`${classes.rootPaper} shadow`} onSubmit={handleSaveMake}>
          <div className={classes.rootInputWrap}>
            <InputBase
              className={classes.input}
              placeholder="Add Make Name"
              inputProps={{ 'aria-label': 'add make name' }}
              value={makeName}
              onChange={e => setMakeName(e.target.value)}
            />
            <IconButton className={classes.iconButton} aria-label="search" onClick={handleSaveMake}>
              <SaveIcon />
            </IconButton>
          </div>
        </Paper>
        <div className="overflow-y-auto" style={{ height: 'calc(100% - 48px)' }}>
          <List dense={true}>
            {allMakes.map(x => {
              return (
                <ListItem key={x.maketitle}>
                  <ListItemText
                    primary={x.maketitle}
                  />
                </ListItem>
              )
            })}
          </List>
        </div>
      </div>
      <div className="shadow flex-1 ml-4">
        <Paper component="form" className={`${classes.rootPaper} shadow`} onSubmit={handleSaveModel}>
          <Autocomplete
            options={allMakes}
            getOptionLabel={option => option.maketitle}
            autoComplete
            includeInputInList
            renderInput={params =>
              <TextField
                {...params}
                label="Select Make"
                margin="normal"
                className="mx-2"
                style={{ width: '-webkit-fill-available' }}
              />
            }
            value={selectedMake}
            onChange={(event, value) => {
              if(value) {
                handleChangeMake(value._id)
              }
              return setSelectedMake(value ? value : null);
            }}
          />
          <div className={`${classes.rootInputWrap} mt-2`}>
            <InputBase
              className={classes.input}
              placeholder="Add Model Name"
              inputProps={{ 'aria-label': 'add model name' }}
              value={modelName}
              onChange={e => setModelName(e.target.value)}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleSaveModel}>
              <SaveIcon />
            </IconButton>
          </div>
        </Paper>
        <div className="overflow-y-auto" style={{ height: 'calc(100% - 128px)' }}>
          <List dense={true}>
            {allModels.map(x => {
              return (
                <ListItem key={x.modeltitle}>
                  <ListItemText
                    primary={x.modeltitle}
                  />
                </ListItem>
              )
            })}
          </List>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  ...state.MakeModel
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction, sendNotificationAction }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MakeModel);