import React, { useEffect } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/ContactDetails/Actions';
import { setHeaderNameAction } from './../../redux/MainLayout/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import TablePaginationActions from './../Users/TablePaginationActions';
import Loader from './../../components/Loader';

const useStyles = makeStyles({
  table: {
    minWidth: 500
  },
});

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'mobile_number', label: 'Mobile Number', minWidth: 170 },
  { id: 'subject', label: 'Subject', minWidth: 170 },
  { id: 'message', label: 'Message', minWidth: 170 },
  { id: 'actions', label: 'Actions', minWidth: 170 },
];

const ContactDetails = (props) => {
  const {
    limit,
    currentPage,
    totalResults,
    loading,
    contactDetailData,
    fetchAllContactDetailsAction,
    resetContactDetailsDataAction,
    changePageNumberAction,
    changePageLimitAction,
    setHeaderNameAction,
    deleteContactDetailAction,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    setHeaderNameAction('ContactDetails');
    return () => {
      resetContactDetailsDataAction();
    };
  }, [setHeaderNameAction, resetContactDetailsDataAction]);

  useEffect(() => {
    if (loading) {
      const payload = {
        page: currentPage,
        limit
      };
      fetchAllContactDetailsAction(payload);
    }
  }, [limit, currentPage, loading, fetchAllContactDetailsAction]);

  const emptyRows = limit - Math.min(limit, contactDetailData.length - currentPage * limit);

  const handleChangePage = (event, newPage) => {
    changePageNumberAction(newPage + 1);
  };

  const handleChangeLimit = event => {
    changePageLimitAction(parseInt(event.target.value, 10));
  };

  const handleDeleteContactDetail = id => {
    deleteContactDetailAction(id);
  }

  return (
    loading ?
      <Loader />
      :
      <TableContainer
        component={Paper}
      // style={{ height: 'calc(100vh - 180px)' }}
      >
        <Table stickyHeader className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                // style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {contactDetailData.length > 0 ?
              contactDetailData.map(contactDetail => (
                <TableRow key={contactDetail._id}>
                  <TableCell component="th" scope="row">
                    {contactDetail.fullname}
                  </TableCell>
                  <TableCell>{contactDetail.email}</TableCell>
                  <TableCell>{contactDetail.mobile_number}</TableCell>
                  <TableCell>{contactDetail.subject}</TableCell>
                  <TableCell>{contactDetail.message}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteContactDetail(contactDetail._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
              :
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={6}
                count={totalResults}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                  defaultValue: limit,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeLimit}
                ActionsComponent={TablePaginationActions}
                rowsPerPage={limit}
                page={currentPage - 1}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
  )
}

const mapStateToProps = (state) => ({
  ...state.ContactDetails
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);