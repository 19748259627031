import { FETCH_INSURANCE, RESET_INSURANCE, UPDATE_PAGE_NUMBER, UPDATE_PAGE_LIMIT, SEARCH_TERM_ADDED } from './Constants';
import Api from '../../helpers/ApiHandler';

const api = new Api();

export const fetchAllInsurancesAction = (payload) => (dispatch) => {
    api.post(`admin_master/get-insurance-list`, { data: payload })
        .then((result) => {
            if (result.success) {
                dispatch({
                    type: FETCH_INSURANCE,
                    data: {
                        InsurancesData: result.data,
                        totalResults: result.totalCount,
                    },
                });
            } else {
                dispatch({
                    type: FETCH_INSURANCE,
                    data: {
                        InsurancesData: [],
                        totalResults: 0,
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err);
        });
};

export const resetInsurancesDataAction = () => (dispatch) => {
    dispatch({
        type: RESET_INSURANCE,
    });
};

export const changePageNumberAction = (pageNumber) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_NUMBER,
        data: pageNumber,
    });
};

export const changePageLimitAction = (limit) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_LIMIT,
        data: limit,
    });
};

export const searchTermAddedAction = () => (dispatch) => {
    dispatch({
        type: SEARCH_TERM_ADDED,
    });
};
