import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BuildIcon from '@material-ui/icons/Build';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ContactsIcon from '@material-ui/icons/Contacts';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import AppsIcon from '@material-ui/icons/Apps';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ReportIcon from '@material-ui/icons/Report';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import { Link } from "react-router-dom";

export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component={Link} to="/users">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItem>
    <ListItem button component={Link} to="/technicians">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <BuildIcon />
      </ListItemIcon>
      <ListItemText primary="Technicians" />
    </ListItem>
    <ListItem button component={Link} to="/insurances">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <LocalHospitalIcon />
      </ListItemIcon>
      <ListItemText primary="Insurances" />
    </ListItem>
    <ListItem button component={Link} to="/subscriptions">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Subscriptions" />
    </ListItem>
    <ListItem button component={Link} to="/listings">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Car Listing" />
    </ListItem>
    {/* <ListItem button component={Link} to="/orders">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <SubscriptionsIcon />
      </ListItemIcon>
      <ListItemText primary="Orders" />
    </ListItem> */}
    <ListItem button component={Link} to="/test-drive">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <DirectionsCarIcon />
      </ListItemIcon>
      <ListItemText primary="Test Drives" />
    </ListItem>
    <ListItem button component={Link} to="/contact-details">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <ContactsIcon />
      </ListItemIcon>
      <ListItemText primary="Contact Details" />
    </ListItem>
    <ListItem button component={Link} to="/blogs">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <AppsIcon />
      </ListItemIcon>
      <ListItemText primary="Blogs" />
    </ListItem>
    <ListItem button component={Link} to="/make-model">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <ViewAgendaIcon />
      </ListItemIcon>
      <ListItemText primary="Make/Model" />
    </ListItem>
    <ListItem button component={Link} to="/chat-reported-users">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <ReportIcon />
      </ListItemIcon>
      <ListItemText primary="Chat Reported Users" />
    </ListItem>
    <ListItem button component={Link} to="/reported-stolen-cars">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <ReportProblemIcon />
      </ListItemIcon>
      <ListItemText primary="Reported Stolen Cars" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button component={Link} to="/">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button component={Link} to="/">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button component={Link} to="/">
      <ListItemIcon style={{ padding: '0 24px 0 8px', minWidth: 0 }}>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);