import {
  FETCH_BLOGS,
  RESET_BLOGS,
  UPDATE_PAGE_NUMBER,
  UPDATE_PAGE_LIMIT,
  FETCH_SINGLE_BLOG,
  ADD_BLOG,
  UPDATE_BLOG,
  DELETE_BLOG,
} from './Constants';
import { ADDED_NOTIFICATION } from "../Notification/Constants";

import Api from '../../helpers/ApiHandler';
const api = new Api();

export const fetchAllBlogsAction = (payload) => dispatch => {
  api.post(`blogs/list-blogs`, { data: payload })
    .then(result => {
      if (result.success) {
        dispatch({
          type: FETCH_BLOGS,
          data: {
            blogsData: result.data,
            totalResults: result.count_records,
          }
        })
      } else {
        dispatch({
          type: FETCH_BLOGS,
          data: {
            blogsData: [],
            totalResults: 0,
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const resetBlogsDataAction = () => dispatch => {
  dispatch({
    type: RESET_BLOGS
  })
}

export const changePageNumberAction = (pageNumber) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_NUMBER,
    data: pageNumber
  })
}

export const changePageLimitAction = (limit) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_LIMIT,
    data: limit
  })
}

export const fetchSingleBlogAction = (id) => dispatch => {
  api.get(`blogs/get-blog-by-id/${id}`)
    .then(result => {
      if (result.success) {
        dispatch({
          type: FETCH_SINGLE_BLOG,
          data: result.data
        })
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const saveBlogAction = (payload) => dispatch => {
  api.post(`blogs/add-blog`, { data: payload, isMultipart: true })
    .then(result => {
      if (result.success) {
        dispatch({
          type: ADD_BLOG
        })
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'success',
            message: result.msg
          }
        })
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const updateBlogAction = (payload) => dispatch => {
  api.put(`blogs/update-blog`, { data: payload, isMultipart: true })
    .then(result => {
      if (result.success) {
        dispatch({
          type: UPDATE_BLOG
        })
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'success',
            message: result.msg
          }
        })
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const deleteBlogAction = id => dispatch => {
  api.delete(`blogs/delete-blog-by-id/${id}`)
    .then(result => {
      if (result.success) {
        dispatch({
          type: DELETE_BLOG,
          data: id
        })
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'success',
            message: result.msg
          }
        })
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}