import { FETCH_DEALER_CARS, RESET_DEALER_CARS, UPDATE_PAGE_NUMBER, UPDATE_PAGE_LIMIT, SEARCH_TERM_ADDED } from './Constants';
import Api from './../../helpers/ApiHandler';

// Create an instance of the ApiHandler
const api = new Api();

/** Action to fetch all dealer cars */
export const fetchAllDealerCarsAction = (payload) => (dispatch) => {
    // Make a POST request to fetch dealer cars data
    api.post(`admin_master/get-dealer-cars`, { data: payload })
        .then((result) => {
            // Check if the request was successful
            if (result.success) {
                // Dispatch action with fetched dealer cars data if successful
                dispatch({
                    type: FETCH_DEALER_CARS,
                    data: {
                        dealerCarsData: result.data,     // Assign fetched dealer cars data
                        totalResults: result.totalCount, // Assign total count of results
                    },
                });
            } else {
                // Dispatch action with empty dealer cars data if unsuccessful
                dispatch({
                    type: FETCH_DEALER_CARS,
                    data: {
                        dealerCarsData: [], // Assign empty array for dealer cars data
                        totalResults: 0,    // Assign total results count as 0
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err); // Log any errors to console
        });
};

/** Action to reset dealer cars data */
export const resetDealerCarsDataAction = () => (dispatch) => {
    dispatch({
        type: RESET_DEALER_CARS, // Dispatch action to reset dealer cars data
    });
};

/** Action to change current page number */
export const changePageNumberAction = (pageNumber) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_NUMBER, // Dispatch action to update page number
        data: pageNumber,         // Pass the new page number as data
    });
};

/** Action to change page limit */
export const changePageLimitAction = (limit) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_LIMIT, // Dispatch action to update page limit
        data: limit,             // Pass the new limit as data
    });
};

/** Action when search term is added */
export const searchTermAddedAction = () => (dispatch) => {
    dispatch({
        type: SEARCH_TERM_ADDED, // Dispatch action when search term is added
    });
}