import {
    FETCH_CHATREPORTEDUSERS,
    RESET_CHATREPORTEDUSERS,
    UPDATE_CHATREPORTEDUSERS,
    UPDATE_PAGE_NUMBER,
    UPDATE_PAGE_LIMIT,
    SEARCH_TERM_ADDED
} from './Constants';

const initialState = {
    ChatReportedUsersData: [],
    loading: true,
    totalResults: 0,
    currentPage: 1,
    limit: 25,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHATREPORTEDUSERS:
            return {
                ...state,
                ...action.data,
                loading: false,
            }
        case UPDATE_PAGE_NUMBER:
            return {
                ...state,
                currentPage: action.data,
                loading: true,
            }
        case UPDATE_PAGE_LIMIT:
            return {
                ...state,
                limit: action.data,
                loading: true,
            }

        case UPDATE_CHATREPORTEDUSERS:
            let index = state.ChatReportedUsersData.findIndex(v => v._id === action.data);
            let newtotalResults = state.totalResults
            if (index !== -1) {
                let newChatReportedUsersData = [...state.ChatReportedUsersData];
                newChatReportedUsersData.splice(index, 1);
                return {
                    ...state,
                    ChatReportedUsersData: newChatReportedUsersData,
                    totalResults: newtotalResults - 1
                };
            }
            return state;

        case SEARCH_TERM_ADDED:
            return {
                ...state,
                loading: true,
                currentPage: 1,
                limit: 25
            }
        case RESET_CHATREPORTEDUSERS:
            return {
                ...state,
                ChatReportedUsersData: [],
                loading: true,
                totalResults: 0,
                currentPage: 1,
                limit: 25,
            }
        default:
            return state
    }
}