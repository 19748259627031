import {
    FETCH_TECHNICIAN,
    RESET_TECHNICIAN,
    UPDATE_TECHNICIAN,
    UPDATE_PAGE_NUMBER,
    UPDATE_PAGE_LIMIT,
    SEARCH_TERM_ADDED
} from './Constants';

const initialState = {
    techniciansData: [],
    loading: true,
    totalResults: 0,
    currentPage: 1,
    limit: 25,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TECHNICIAN:
            return {
                ...state,
                ...action.data,
                loading: false,
            }
        case UPDATE_PAGE_NUMBER:
            return {
                ...state,
                currentPage: action.data,
                loading: true,
            }
        case UPDATE_PAGE_LIMIT:
            return {
                ...state,
                limit: action.data,
                loading: true,
            }
        case UPDATE_TECHNICIAN:
            return {
                ...state,
                techniciansData: state.techniciansData.map(x => {
                    if (x._id === action.data._id) {
                        return action.data
                    }

                    return x;
                })
            }
        case SEARCH_TERM_ADDED:
            return {
                ...state,
                loading: true,
                currentPage: 1,
                limit: 25
            }
        case RESET_TECHNICIAN:
            return {
                ...state,
                techniciansData: [],
                loading: true,
                totalResults: 0,
                currentPage: 1,
                limit: 25,
            }
        default:
            return state
    }
}