import React, { useEffect, useState } from 'react'
// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/Technicians/Actions';
import { setHeaderNameAction } from '../../redux/MainLayout/Actions';


import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import TablePaginationActions from './TablePaginationActions'
import Loader from '../../components/Loader'
import TechnicianActionDialog from '../../components/Technician/TechnicianActionDialog'


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
    },
    rootPaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}))

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'gsm', label: 'Cell', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
    { id: 'status', label: 'Status', minWidth: 170 },
    { id: 'actions', label: 'Actions', minWidth: 170 },
]

const Technician = (props) => {
    const {
        limit,
        currentPage,
        totalResults,
        loading,
        techniciansData,
        fetchAllTechniciansAction,
        resetTechniciansDataAction,
        changePageNumberAction,
        changePageLimitAction,
        setHeaderNameAction,
        searchTermAddedAction,
        updateTechnicianStatusAction,
    } = props

    const classes = useStyles()
    const [selectedTechnicianIndex, setselectedTechnicianIndex] = useState(null)
    const [isTechnicianDialogOpen, setIsTechnicianDialogOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')


    useEffect(() => {
        setHeaderNameAction('Technician');
        return () => {
            resetTechniciansDataAction();
        }
    }, [setHeaderNameAction, resetTechniciansDataAction]);

    useEffect(() => {
        if (loading) {
            const payload = {
                page: currentPage,
                limit,
                search: searchTerm,
            }
            fetchAllTechniciansAction(payload)
        }
    }, [limit, currentPage, loading, fetchAllTechniciansAction, searchTerm])

    const emptyRows =
        limit - Math.min(limit, techniciansData.length - currentPage * limit)

    const handleChangePage = (event, newPage) => {
        changePageNumberAction(newPage + 1)
    }

    const handleChangeLimit = (event) => {
        changePageLimitAction(parseInt(event.target.value, 10))
    }

    const handleSelectTechnician = (index) => {
        setselectedTechnicianIndex(index)
        setIsTechnicianDialogOpen(true)
    }

    const handleCloseTechnicianDialog = () => {
        setIsTechnicianDialogOpen(false)
        setselectedTechnicianIndex(null)
    }

    let technicianData = undefined
    if (selectedTechnicianIndex !== null) {
        technicianData = { ...techniciansData[selectedTechnicianIndex] }
    }

    const handleSearchSubmit = (e) => {
        e && e.preventDefault()

        searchTermAddedAction()
    }

    const handleChangeTechnicianStatus = (payload) => {
        updateTechnicianStatusAction(payload)
    }

    return (
        <React.Fragment>
            <div className="d-flex justify-content-end mb-3">
                <Paper
                    component="form"
                    className={classes.rootPaper}
                    onSubmit={handleSearchSubmit}
                >
                    <InputBase
                        className={classes.input}
                        placeholder="Search Technician"
                        inputProps={{ 'aria-label': 'search technician' }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <IconButton
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={handleSearchSubmit}
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <React.Fragment>
                    <TableContainer
                        component={Paper}
                    // style={{ height: 'calc(100vh - 180px)' }}
                    >
                        <Table
                            stickyHeader
                            className={classes.table}
                            aria-label="custom pagination table"
                        >
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {techniciansData.length > 0 ? (
                                    techniciansData.map((technician, index) => (
                                        <TableRow key={technician._id}>
                                            <TableCell component="th" scope="row">
                                                {technician.fullname}
                                            </TableCell>
                                            <TableCell>{technician.gsm}</TableCell>
                                            <TableCell>{technician.email}</TableCell>
                                            <TableCell>
                                                {technician.status === 'active' ? "Active" : technician.status === "inactive" ? 'Inactive' : technician.status === 'suspended' ? 'Suspended' : 'Inactive'}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleSelectTechnician(index)}>
                                                    <PermIdentityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={6}
                                        count={totalResults}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                            defaultValue: limit,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeLimit}
                                        ActionsComponent={TablePaginationActions}
                                        rowsPerPage={limit}
                                        page={currentPage - 1}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <TechnicianActionDialog
                        open={isTechnicianDialogOpen}
                        handleClose={handleCloseTechnicianDialog}
                        technicianData={technicianData}
                        handleChangeTechnicianStatus={handleChangeTechnicianStatus}
                    />
                </React.Fragment>
            )}
        </React.Fragment>

    )
}


const mapStateToProps = (state) => ({
    ...state.Technicians
})

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Technician)


