import React, { useEffect } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Blogs/Actions';
import { setHeaderNameAction } from './../../redux/MainLayout/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import TablePaginationActions from './../Users/TablePaginationActions';
import { Link } from 'react-router-dom';
import Loader from './../../components/Loader';

const useStyles = makeStyles({
  table: {
    minWidth: 500
  },
});

const columns = [
  { id: 'title', label: 'Title', minWidth: 170 },
  // { id: 'image', label: 'Image', minWidth: 170 },
  // { id: 'category', label: 'Category', minWidth: 170 },
  { id: 'slug', label: 'slug', minWidth: 170 },
  { id: 'actions', label: 'Actions', minWidth: 170 },
];

const Blogs = (props) => {
  const {
    limit,
    currentPage,
    totalResults,
    loading,
    blogsData,
    fetchAllBlogsAction,
    resetBlogsDataAction,
    changePageNumberAction,
    changePageLimitAction,
    setHeaderNameAction,
    deleteBlogAction,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    setHeaderNameAction('Blogs');
    return () => {
      resetBlogsDataAction();
    };
  }, [setHeaderNameAction, resetBlogsDataAction]);

  useEffect(() => {
    if (loading) {
      const payload = {
        page: currentPage,
        limit
      };
      fetchAllBlogsAction(payload);
    }
  }, [limit, currentPage, loading, fetchAllBlogsAction]);

  const emptyRows = limit - Math.min(limit, blogsData.length - currentPage * limit);

  const handleChangePage = (event, newPage) => {
    changePageNumberAction(newPage + 1);
  };

  const handleChangeLimit = event => {
    changePageLimitAction(parseInt(event.target.value, 10));
  };

  const handleBlogDelete = id => {
    deleteBlogAction(id);
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end mb-3">
        <Button component={Link} to="/blogs/add" variant="contained" color="primary">
          Add Blog
        </Button>
      </div>
      {loading ?
        <Loader />
        :
        <TableContainer
          component={Paper}
        // style={{ height: 'calc(100vh - 180px)' }}
        >
          <Table stickyHeader className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                  // style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {blogsData.length > 0 ?
                blogsData.map(blog => (
                  <TableRow key={blog._id}>
                    <TableCell component="th" scope="row">
                      {blog.title}
                    </TableCell>
                    {/* <TableCell></TableCell> */}
                    {/* <TableCell>{blog.category}</TableCell> */}
                    <TableCell>{blog.slug}</TableCell>
                    <TableCell>
                      <IconButton component={Link} to={`/blogs/edit/${blog._id}`}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton onClick={() => handleBlogDelete(blog._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={6}
                  count={totalResults}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                    defaultValue: limit,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeLimit}
                  ActionsComponent={TablePaginationActions}
                  rowsPerPage={limit}
                  page={currentPage - 1}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  ...state.Blogs
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);