import React, { useEffect, useState } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/CarListing/Actions';
import { setHeaderNameAction } from './../../redux/MainLayout/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import BuildIcon from '@material-ui/icons/Build';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import TablePaginationActions from './../Users/TablePaginationActions';
import { Link } from 'react-router-dom';
import Loader from './../../components/Loader';
import CarActionDialog from './../../components/CarListing/CarActionDialog';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  rootPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const columns = [
  { id: 'title', label: 'Title', minWidth: 170 },
  { id: 'price', label: 'Price', minWidth: 170, align: 'center' },
  { id: 'email', label: 'Fuel', minWidth: 170, align: 'center' },
  { id: 'package', label: 'Engine', minWidth: 170, align: 'center' },
  { id: 'expiryDate', label: 'Type', minWidth: 170, align: 'center' },
  { id: 'status', label: 'Status', minWidth: 170, align: 'center' },
  { id: 'actions', label: 'Actions', minWidth: 170, align: 'center' },
];

const CarListing = (props) => {
  const {
    filterParams: {
      limit,
      currentPage,
    },
    totalResults,
    loading,
    searchResults,
    getSearchResultsDataAction,
    resetAllFilterAction,
    updatePageNoAction,
    updatePageLimitAction,
    setHeaderNameAction,
    searchTermAddedAction,
    changeCarStatusAction,
  } = props;

  const classes = useStyles();
  const [selectedCarIndex, setSelectedCarIndex] = useState(null);
  const [isCarDialogOpen, setIsCarDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setHeaderNameAction('Car Listing');
    return () => {
      resetAllFilterAction();
    };
  }, [setHeaderNameAction, resetAllFilterAction]);

  useEffect(() => {
    if (loading) {
      const payload = {
        page: currentPage,
        limit,
        search: searchTerm
      };
      getSearchResultsDataAction(payload);
    }
  }, [limit, currentPage, loading, getSearchResultsDataAction, searchTerm]);

  const emptyRows = limit - Math.min(limit, searchResults.length - currentPage * limit);

  let carData = undefined;
  if (selectedCarIndex !== null) {
    carData = { ...searchResults[selectedCarIndex] };
  }

  const handleSelectCar = index => {
    setSelectedCarIndex(index);
    setIsCarDialogOpen(true);
  }

  const handleCloseCarDialog = () => {
    setIsCarDialogOpen(false);
    setSelectedCarIndex(null);
  }

  const handleChangePage = (event, newPage) => {
    updatePageNoAction(newPage + 1);
  };

  const handleChangeLimit = event => {
    updatePageLimitAction(parseInt(event.target.value, 10));
  };

  const handleSearchSubmit = e => {
    e && e.preventDefault();

    searchTermAddedAction();
  }

  const handleChangeCarStatus = payload => {
    changeCarStatusAction(payload);
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end mb-3">
        <Paper component="form" className={classes.rootPaper} onSubmit={handleSearchSubmit}>
          <InputBase
            className={classes.input}
            placeholder="Search Car"
            inputProps={{ 'aria-label': 'search car' }}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <IconButton className={classes.iconButton} aria-label="search" onClick={handleSearchSubmit}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {loading ?
        <Loader />
        :
        <React.Fragment>
          <TableContainer
            component={Paper}
          // style={{ height: 'calc(100vh - 180px)' }}
          >
            <Table stickyHeader className={classes.table} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                    // style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResults.length > 0 ?
                  searchResults.map((car, index) => (
                    <TableRow key={car._id}>
                      <TableCell component="th" scope="row">
                        {`${car.make} ${car.model} ${car.year}`}
                      </TableCell>
                      <TableCell align="center">R{(car.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</TableCell>
                      <TableCell align="center">{car.fueltype}</TableCell>
                      <TableCell align="center">{car.engine} cc</TableCell>
                      <TableCell align="center">
                        {car.featured ?
                          <Chip label="Featured" color="primary" />
                          :
                          <Chip label="Normal" />
                        }
                      </TableCell>
                      <TableCell align="center">
                        {car.status === 'active' ?
                          'Active'
                          :
                          car.status === 'blocked' ?
                            'Blocked'
                            :
                            car.status === 'suspended' ?
                              'Suspended'
                              :
                              'Deleted'
                        }
                      </TableCell>
                      <TableCell align="center">
                        <IconButton component={"a"} target="_blank" href={`${process.env.REACT_APP_MAIN_SITE_URL}/listing/${car.slug}`}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton component={Link} to={`/listings/${car.slug}`}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleSelectCar(index)}>
                          <BuildIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                  :
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={totalResults}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                      defaultValue: limit,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeLimit}
                    ActionsComponent={TablePaginationActions}
                    rowsPerPage={limit}
                    page={currentPage - 1}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <CarActionDialog
            open={isCarDialogOpen}
            handleClose={handleCloseCarDialog}
            carData={carData}
            handleChangeCarStatus={handleChangeCarStatus}
          />
        </React.Fragment>
      }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  ...state.CarListing
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarListing);