import {
  FETCH_BLOGS,
  RESET_BLOGS,
  UPDATE_PAGE_NUMBER,
  UPDATE_PAGE_LIMIT,
  FETCH_SINGLE_BLOG,
  ADD_BLOG,
  UPDATE_BLOG,
  DELETE_BLOG,
} from './Constants';

const initialState = {
  blogsData: [],
  fetchedData: undefined,
  loading: true,
  totalResults: 0,
  currentPage: 1,
  limit: 25,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOGS:
      return {
        ...state,
        ...action.data,
        loading: false,
      }
    case UPDATE_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.data,
        loading: true,
      }
    case UPDATE_PAGE_LIMIT:
      return {
        ...state,
        limit: action.data,
        loading: true,
      }
    case FETCH_SINGLE_BLOG:
      return {
        ...state,
        fetchedData: action.data
      }
    case ADD_BLOG:
      return {
        ...state,
        blogsData: [...state.blogsData, action.data]
      }
    case UPDATE_BLOG:
      return {
        ...state,
        fetchedData: action.data
      }
    case DELETE_BLOG:
      return {
        ...state,
        blogsData: state.blogsData.filter(x => x._id !== action.data),
        totalResults: state.totalResults - 1,
      }
    case RESET_BLOGS:
      return {
        ...state,
        blogsData: [],
        fetchedData: undefined,
        loading: true,
        totalResults: 0,
        currentPage: 1,
        limit: 25,
      }
    default:
      return state
  }
}