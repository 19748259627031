import { FETCH_DEALERS, RESET_DEALERS, UPDATE_PAGE_NUMBER, UPDATE_PAGE_LIMIT, SEARCH_TERM_ADDED } from './Constants';
import Api from './../../helpers/ApiHandler';

const api = new Api();

/** Action to fetch all dealers */
export const fetchAllDealersAction = (payload) => (dispatch) => {
    api.post(`admin_master/get-dealer-list`, { data: payload })
        .then((result) => {
            console.log(result.data); // Log the fetched data to console
            if (result.success) {
                // Dispatch action with fetched dealer data if successful
                dispatch({
                    type: FETCH_DEALERS,
                    data: {
                        dealersData: result.data,    // Assign fetched dealer data
                        totalResults: result.totalCount, // Assign total count of results
                    },
                });
            } else {
                // Dispatch action with empty dealer data if unsuccessful
                dispatch({
                    type: FETCH_DEALERS,
                    data: {
                        dealersData: [],     // Assign empty array for dealer data
                        totalResults: 0,    // Assign total results count as 0
                    },
                });
            }
        })
        .catch((err) => {
            console.log("err", err); // Log any errors to console
        });
};

/** Action to reset dealers data */
export const resetDealersDataAction = () => (dispatch) => {
    dispatch({
        type: RESET_DEALERS, // Dispatch action to reset dealers data
    });
};

/** Action to change current page number */
export const changePageNumberAction = (pageNumber) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_NUMBER,  // Dispatch action to update page number
        data: pageNumber,          // Pass the new page number as data
    });
};

/** Action to change page limit */
export const changePageLimitAction = (limit) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_LIMIT, // Dispatch action to update page limit
        data: limit,             // Pass the new limit as data
    });
};

/** Action when search term is added */
export const searchTermAddedAction = () => (dispatch) => {
    dispatch({
        type: SEARCH_TERM_ADDED, // Dispatch action when search term is added
    });
};
