import {
  GET_ALL_MAKES,
  GET_MODELS,
  ADD_MAKE,
  ADD_MODEL,
  RESET_MAKE_MODEL,
} from './Constants';
import { ADDED_NOTIFICATION } from './../Notification/Constants';

import Api from '../../helpers/ApiHandler';

const api = new Api();

export const fetchAllMakesAction = () => dispatch => {
  api.get('common/get-all-makes')
    .then((result) => {
      dispatch({
        type: GET_ALL_MAKES,
        data: result.data
      })
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export const fetchAllModelsAction = (brandId) => dispatch => {
  api.get(`common/get-single-make/${brandId}`, { skipAuth: true })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: GET_MODELS,
          data: result.data.models
        })
      } else {
        console.log('result', result);
      }
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export const addMakeAction = (payload) => dispatch => {
  api.post(`admin_master/add-make`, { data: payload })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: ADD_MAKE,
          data: result.data
        })
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'success',
            message: 'Make added successfully'
          }
        })
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg
          }
        })
      }
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export const addModelAction = (payload) => dispatch => {
  api.post(`admin_master/add-make-models`, { data: payload })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: ADD_MODEL,
          data: result.data.models
        })
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'success',
            message: 'Model added successfully'
          }
        })
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg
          }
        })
      }
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export const resetMakeModelOptionsAction = () => dispatch => {
  dispatch({
    type: RESET_MAKE_MODEL
  })
}