import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 220,
  },
  submit: {
    width: 100
  },
}));

const availableLimit = [
  { text: '1 Month', value: 1 },
  { text: '2 Months', value: 2 },
  { text: '3 Months', value: 3 },
  { text: '4 Months', value: 4 },
  { text: '5 Months', value: 5 },
  { text: '6 Months', value: 6 },
  { text: '7 Months', value: 7 },
  { text: '8 Months', value: 8 },
  { text: '9 Months', value: 9 },
  { text: '10 Months', value: 10 },
  { text: '11 Months', value: 11 },
  { text: '1 Year', value: 12 },
  { text: '2 Years', value: 24 },
];

const UserActionDialog = ({ open, handleClose, userData, subscriptionsData, handleChangeUserStatus, handleChangeUserSubscriptionStatus, handleApplySubscription }) => {
  const [status, setStatus] = useState('active');
  const [subscriptionStatus, setSubscriptionStatus] = useState('active');
  const [plan, setPlan] = useState(null);
  const [duration, setDuration] = useState(1);
  const classes = useStyles();

  const handleSubmitStatus = e => {
    e && e.preventDefault();
    const useStatus = status;
    setStatus('active');
    handleChangeUserStatus({ user_id: userData._id, status: useStatus })
    handleClose();
  }

  const handleSubmitSubscriptionStatus = e => {
    e && e.preventDefault();
    const action = subscriptionStatus;
    setSubscriptionStatus('active');
    handleChangeUserSubscriptionStatus({ userid: userData._id, action })
    handleClose();
  }

  const handleApplyPlan = e => {
    e && e.preventDefault();

    const newDuration = duration;
    const planid = plan;

    setPlan(null);
    setDuration(1);
    handleApplySubscription({ userid: userData._id, duration: newDuration, planid })
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">User Actions</DialogTitle>
      <DialogContent>
        {userData &&
          <React.Fragment>
            <Typography className="mb-2 font-size-16" component="h3" variant="h6">
              Account
            </Typography>
            <form style={{ display: 'flex' }} onSubmit={e => e.preventDefault()}>
              <FormControl className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'suspended'}>Suspended</MenuItem>
                  <MenuItem value={'blocked'}>Block</MenuItem>
                  <MenuItem value={'deleted'}>Delete</MenuItem>
                </Select>
              </FormControl>
              <Button
                fullWidth
                className={classes.submit}
                variant="contained"
                color="primary"
                style={{ marginLeft: 10, alignSelf: 'flex-end' }}
                onClick={handleSubmitStatus}
              >
                Submit
              </Button>
            </form>

            <Typography className="mt-4 mb-2 font-size-16" component="h3" variant="h6">
              Subscription
            </Typography>
            <form style={{ display: 'flex' }} onSubmit={e => e.preventDefault()}>
              <FormControl className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Subscription</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subscriptionStatus}
                  onChange={e => setSubscriptionStatus(e.target.value)}
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'suspended'}>Suspended</MenuItem>
                  <MenuItem value={'canceled'}>Canceled</MenuItem>
                </Select>
              </FormControl>
              <Button
                fullWidth
                className={classes.submit}
                variant="contained"
                color="primary"
                style={{ marginLeft: 10, alignSelf: 'flex-end' }}
                onClick={handleSubmitSubscriptionStatus}
              >
                Submit
              </Button>
            </form>

            <Typography className="mt-4 mb-2 font-size-16" component="h3" variant="h6">
              Plans
            </Typography>
            <form style={{ display: 'flex' }} onSubmit={e => e.preventDefault()}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Plans</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={plan}
                  onChange={e => setPlan(e.target.value)}
                >
                  {subscriptionsData.map(subscription => {
                    return <MenuItem value={subscription._id}>{subscription.title}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl className={`${classes.formControl} ml-2`} >
                <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={duration}
                  onChange={e => setDuration(e.target.value)}
                >
                  {availableLimit.map(limit => {
                    return <MenuItem value={limit.value}>{limit.text}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <Button
                fullWidth
                className={classes.submit}
                variant="contained"
                color="primary"
                style={{ marginLeft: 10, alignSelf: 'flex-end' }}
                onClick={handleApplyPlan}
              >
                Submit
              </Button>
            </form>
          </React.Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserActionDialog;