import {
  FETCH_VEHICLE_BRAND_CAR_EDIT,
  FETCH_VEHICLE_MODEL_CAR_EDIT,
  FETCH_CAR_DATA,
  FETCH_CAR_DATA_FAILURE,
  REMOVE_MODEL_OPTIONS,
  RESET_CAR_EDIT_OPTION,
  ADD_CAR,
  ADD_CAR_FAILURE,
  UPDATE_CAR,
  UPDATE_CAR_FAILURE,
  SET_LOADING,
  DELETE_CAR_IMAGE,
} from './Constants';
import { ADDED_NOTIFICATION } from "../Notification/Constants";
import Api from '../../helpers/ApiHandler';

const api = new Api();

export const fetchVehicleBrandAction = () => dispatch => {
  api.get('common/get-all-makes')
    .then((result) => {
      dispatch({
        type: FETCH_VEHICLE_BRAND_CAR_EDIT,
        data: result.data
      })
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export const fetchVehicleModelAction = (brand) => dispatch => {
  api.get(`common/get-single-make/${brand._id}`, { skipAuth: true })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: FETCH_VEHICLE_MODEL_CAR_EDIT,
          data: result.data.models
        })
      } else {
        console.log('result', result);
      }
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export const addCarAction = (payload) => dispatch => {
  api.post(`cars/add-car`, { data: payload, isMultipart: true })
    .then(result => {
      if(result.success) {
        dispatch({
          type: ADD_CAR,
          data: result.msg
        })
      } else {
        dispatch({
          type: ADD_CAR_FAILURE,
          data: result.msg
        })
      }
    })
    .catch(err => {
      console.log('err', err);
    })
}

export const updateCarDataAction = (payload) => dispatch => {
  api.post(`cars/update-car-details`, { data: payload, isMultipart: true })
    .then(result => {
      if(result.success) {
        dispatch({
          type: UPDATE_CAR,
          data: result.msg
        })
      } else {
        dispatch({
          type: UPDATE_CAR_FAILURE,
          data: result.msg
        })
      }
    })
    .catch(err => {
      console.log('err', err);
    })
}

export const fetchCarDataAction = (id) => dispatch => {
  api.get(`cars/get-single-car/${id}`, { skipAuth: true })
    .then(result => {
      if(result.success) {
        dispatch({
          type: FETCH_CAR_DATA,
          data: result.data
        })
      } else {
        dispatch({
          type: FETCH_CAR_DATA_FAILURE,
          data: result.msg
        })
      }
    })
    .catch(err => {
      console.log('err', err);
    })
}

export const deleteCarImageAction = (payload) => dispatch => {
  api.delete(`cars/delete-car-single-image`, { data: payload })
    .then(result => {
      if(result.success) {
        dispatch({
          type: DELETE_CAR_IMAGE,
          data: payload.image_url
        })
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err);
    })
}

export const removeModelsAction = () => dispatch => {
  dispatch({
    type: REMOVE_MODEL_OPTIONS
  })
}

export const setLoadingAction = () => dispatch => {
  dispatch({
    type: SET_LOADING
  })
}

export const resetAllCarEditOptionAction = () => dispatch => {
  dispatch({
    type: RESET_CAR_EDIT_OPTION
  })
}

export const sendNotificationAction = (type, message) => dispatch => {
  dispatch({
    type: ADDED_NOTIFICATION,
    data: {
      type,
      message,
    },
  })
}