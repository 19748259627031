import {
  DashboardPage,
  LoginPage,
  UsersPage,
  TechnicianPage,
  InsurancesPage,
  ReportedStolenCarsPage,
  ChatReportedUsersPage,
  OrdersPage,
  SubscriptionsPage,
  MakeModelPage,
  BlogsPage,
  BlogAddPage,
  CarListingPage,
  CarEditPage,
  TestDrivePage,
  ContactDetailsPage,
  DealersPage,
  DealerCarsPage,
} from './../pages';

export const openRoutes = [
  {
    path: '/login',
    name: 'Login',
    exact: true,
    component: LoginPage
  },
];

export const protectedRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    exact: true,
    component: DashboardPage
  },
  {
    path: '/users',
    name: 'Users',
    exact: true,
    component: UsersPage
  },
  {
    path: '/technicians',
    name: 'Technicians',
    exact: true,
    component: TechnicianPage
  },
  {
    path: '/dealers',
    name: 'Dealers',
    exact: true,
    component: DealersPage
  },
  {
    path: '/insurances',
    name: 'Insurances',
    exact: true,
    component: InsurancesPage
  },
  {
    path: '/chat-reported-users',
    name: 'ChatReportedUsers',
    exact: true,
    component: ChatReportedUsersPage
  },
  {
    path: '/reported-stolen-cars',
    name: 'ReportedStolenCars ',
    exact: true,
    component: ReportedStolenCarsPage
  },
  {
    path: '/orders',
    name: 'Orders',
    exact: true,
    component: OrdersPage
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    exact: true,
    component: SubscriptionsPage
  },
  {
    path: '/make-model',
    name: 'MakeModelYear',
    exact: true,
    component: MakeModelPage
  },
  {
    path: '/blogs',
    name: 'Blogs',
    exact: true,
    component: BlogsPage
  },
  {
    path: '/blogs/add',
    name: 'Blog Add',
    exact: true,
    component: BlogAddPage
  },
  {
    path: '/blogs/edit/:id',
    name: 'Blog Edit',
    exact: true,
    component: BlogAddPage
  },
  {
    path: '/listings',
    name: 'Car Listing',
    exact: true,
    component: CarListingPage
  },
  {
    path: '/listings/:id',
    name: 'Car Edit',
    exact: true,
    component: CarEditPage
  },
  {
    path: '/dealerId/:id',
    name: 'Dealer Car',
    exact: true,
    component: DealerCarsPage
  },
  {
    path: '/test-drive',
    name: 'Book Test Drive',
    exact: true,
    component: TestDrivePage
  },
  {
    path: '/contact-details',
    name: 'Contact Us Details',
    exact: true,
    component: ContactDetailsPage
  },
];