import React, { useEffect, useState } from 'react'

// For redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Actions from './../../redux/Users/Actions'
import { setHeaderNameAction } from './../../redux/MainLayout/Actions'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'

import TablePaginationActions from './TablePaginationActions'
import Loader from './../../components/Loader'
import UserActionDialog from './../../components/Users/UserActionDialog'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  rootPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}))

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'gsm', label: 'Cell', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'package', label: 'Package', minWidth: 170 },
  { id: 'expiryDate', label: 'Expiry Date', minWidth: 170 },
  { id: 'status', label: 'Status', minWidth: 170 },
  { id: 'actions', label: 'Actions', minWidth: 170 },
]

const Users = (props) => {
  const {
    limit,
    currentPage,
    totalResults,
    subscriptionsData,
    loading,
    isSubscriptionsLoading,
    usersData,
    fetchAllUsersAction,
    resetUsersDataAction,
    changePageNumberAction,
    changePageLimitAction,
    setHeaderNameAction,
    searchTermAddedAction,
    fetchAllSubscriptionsAction,
    applyUserSubscriptionAction,
    updateUserSubscriptionAction,
    updateUserStatusAction,
  } = props

  const classes = useStyles()

  const [selectedUserIndex, setSelectedUserIndex] = useState(null)
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setHeaderNameAction('Users')
    return () => {
      resetUsersDataAction()
    }
  }, [setHeaderNameAction, resetUsersDataAction])

  useEffect(() => {
    if (loading) {
      const payload = {
        page: currentPage,
        limit,
        search: searchTerm,
      }
      fetchAllUsersAction(payload)
    }
  }, [limit, currentPage, loading, fetchAllUsersAction, searchTerm])

  useEffect(() => {
    if (isSubscriptionsLoading) {
      fetchAllSubscriptionsAction()
    }
  }, [isSubscriptionsLoading, fetchAllSubscriptionsAction])

  const emptyRows =
    limit - Math.min(limit, usersData.length - currentPage * limit)

  const handleChangePage = (event, newPage) => {
    changePageNumberAction(newPage + 1)
  }

  const handleChangeLimit = (event) => {
    changePageLimitAction(parseInt(event.target.value, 10))
  }

  const handleSelectUser = (index) => {
    setSelectedUserIndex(index)
    setIsUserDialogOpen(true)
  }

  const handleCloseUserDialog = () => {
    setIsUserDialogOpen(false)
    setSelectedUserIndex(null)
  }

  let userData = undefined
  if (selectedUserIndex !== null) {
    userData = { ...usersData[selectedUserIndex] }
  }

  const handleSearchSubmit = (e) => {
    e && e.preventDefault()

    searchTermAddedAction()
  }

  const handleChangeUserStatus = (payload) => {
    updateUserStatusAction(payload)
  }

  const handleChangeUserSubscriptionStatus = (payload) => {
    updateUserSubscriptionAction(payload)
  }

  const handleApplySubscription = (payload) => {
    applyUserSubscriptionAction(payload)
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end mb-3">
        <Paper
          component="form"
          className={classes.rootPaper}
          onSubmit={handleSearchSubmit}
        >
          <InputBase
            className={classes.input}
            placeholder="Search User"
            inputProps={{ 'aria-label': 'search user' }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={handleSearchSubmit}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <TableContainer
            component={Paper}
          // style={{ height: 'calc(100vh - 180px)' }}
          >
            <Table
              stickyHeader
              className={classes.table}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                    // style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {usersData.length > 0 ? (
                  usersData.map((user, index) => (
                    <TableRow key={user._id}>
                      <TableCell component="th" scope="row">
                        {user.fullname}
                      </TableCell>
                      <TableCell>{user.gsm}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user.subscriptions[0].type === 'paid' &&
                          user.subscriptions[0].status === 'active'
                          ? 'Paid Package'
                          : user.subscriptions[0].type === 'expired' ||
                            user.subscriptions[0].status === 'expired'
                            ? 'Package Expired'
                            : user.subscriptions[0].type === 'suspended' ||
                              user.subscriptions[0].status === 'suspended'
                              ? 'Been Suspended'
                              : user.subscriptions[0].type === 'canceled' ||
                                user.subscriptions[0].status === 'canceled'
                                ? 'Been Canceled'
                                : user.subscriptions[0].type === 'free' &&
                                  user.subscriptions[0].status === 'active'
                                  ? 'Free Package'
                                  : 'Unknown Package'}
                      </TableCell>
                      <TableCell>{user.subscriptions[0].expirycdate}</TableCell>
                      <TableCell>
                        {user.status === 'active'
                          ? 'Active'
                          : user.status === 'blocked'
                            ? 'Blocked'
                            : user.status === 'suspended'
                              ? 'Suspended'
                              : user.status === 'not_verified'
                                ? 'Not Verified'
                                : 'Deleted'}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleSelectUser(index)}>
                          <PermIdentityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={totalResults}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                      defaultValue: limit,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeLimit}
                    ActionsComponent={TablePaginationActions}
                    rowsPerPage={limit}
                    page={currentPage - 1}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <UserActionDialog
            open={isUserDialogOpen}
            handleClose={handleCloseUserDialog}
            userData={userData}
            subscriptionsData={subscriptionsData}
            handleChangeUserStatus={handleChangeUserStatus}
            handleChangeUserSubscriptionStatus={
              handleChangeUserSubscriptionStatus
            }
            handleApplySubscription={handleApplySubscription}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  ...state.Users,
})

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
