import {
  FETCH_TEST_DRIVE,
  RESET_TEST_DRIVE,
  UPDATE_PAGE_NUMBER,
  UPDATE_PAGE_LIMIT,
  DELETE_TEST_DRIVE,
  SEARCH_TERM_ADDED,
} from './Constants';
// import { ADDED_NOTIFICATION } from "./../Notification/Constants";

import Api from '../../helpers/ApiHandler';
const api = new Api();

export const fetchAllTestDrivesAction = (payload) => dispatch => {
  api.post(`cars/test-drive/list-booked-test-drives`, { data: payload })
    .then(result => {
      if (result.success) {
        dispatch({
          type: FETCH_TEST_DRIVE,
          data: {
            testDriveData: result.data,
            totalResults: result.totalCount,
          }
        })
      } else {
        dispatch({
          type: FETCH_TEST_DRIVE,
          data: {
            testDriveData: [],
            totalResults: 0,
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const deleteTestDriveDetailAction = id => dispatch => {
  api.delete(`cars/test-drive/delete-single-record-by-id/${id}`)
    .then(result => {
      if (result.success) {
        dispatch({
          type: DELETE_TEST_DRIVE,
          data: id
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const resetTestDrivesDataAction = () => dispatch => {
  dispatch({
    type: RESET_TEST_DRIVE
  })
}

export const changePageNumberAction = (pageNumber) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_NUMBER,
    data: pageNumber
  })
}

export const changePageLimitAction = (limit) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_LIMIT,
    data: limit
  })
}

export const searchTermAddedAction = () => dispatch => {
  dispatch({
    type: SEARCH_TERM_ADDED
  })
}