import {
  FETCH_CONTACT_DETAILS,
  RESET_CONTACT_DETAILS,
  UPDATE_PAGE_NUMBER,
  UPDATE_PAGE_LIMIT,
  DELETE_CONTACT_DETAILS,
} from './Constants';
// import { ADDED_NOTIFICATION } from "../Notification/Constants";

import Api from '../../helpers/ApiHandler';
const api = new Api();

export const fetchAllContactDetailsAction = (payload) => dispatch => {
  api.post(`contact_us/get-contact-us-list`, { data: payload })
    .then(result => {
      if (result.success) {
        dispatch({
          type: FETCH_CONTACT_DETAILS,
          data: {
            contactDetailData: result.data,
            totalResults: result.count_records,
          }
        })
      } else {
        dispatch({
          type: FETCH_CONTACT_DETAILS,
          data: {
            contactDetailData: [],
            totalResults: 0,
          }
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const deleteContactDetailAction = id => dispatch => {
  api.delete(`contact_us/delete-single-record/${id}`)
    .then(result => {
      if (result.success) {
        dispatch({
          type: DELETE_CONTACT_DETAILS,
          data: id
        })
      }
    })
    .catch(err => {
      console.log('err', err)
    });
}

export const resetContactDetailsDataAction = () => dispatch => {
  dispatch({
    type: RESET_CONTACT_DETAILS
  })
}

export const changePageNumberAction = (pageNumber) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_NUMBER,
    data: pageNumber
  })
}

export const changePageLimitAction = (limit) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_LIMIT,
    data: limit
  })
}