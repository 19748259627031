import {
  FETCH_USERS,
  RESET_USERS,
  UPDATE_PAGE_NUMBER,
  UPDATE_PAGE_LIMIT,
  SEARCH_TERM_ADDED,
  FETCH_SUBSCRIPTIONS,
  APPLY_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_USER,
} from "./Constants";
import { ADDED_NOTIFICATION } from "./../Notification/Constants";

import Api from "../../helpers/ApiHandler";
const api = new Api();

export const fetchAllUsersAction = (payload) => (dispatch) => {
  api
    .post(`admin_master/get-users-list`, { data: payload })
    .then((result) => {
      if (result.success) {
        dispatch({
          type: FETCH_USERS,
          data: {
            usersData: result.data,
            totalResults: result.totalCount,
          },
        });
      } else {
        dispatch({
          type: FETCH_USERS,
          data: {
            usersData: [],
            totalResults: 0,
          },
        });
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const fetchAllSubscriptionsAction = () => (dispatch) => {
  api
    .get(`admin_master/get-subscription-list`)
    .then((result) => {
      if (result.success) {
        dispatch({
          type: FETCH_SUBSCRIPTIONS,
          data: {
            subscriptionsData: result.data,
          },
        });
      } else {
        dispatch({
          type: FETCH_SUBSCRIPTIONS,
          data: {
            subscriptionsData: [],
          },
        });
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const applyUserSubscriptionAction = (payload) => (dispatch) => {
  api
    .post(`admin_master/renew/subscriptions`, { data: payload })
    .then((result) => {
      if (result.success) {
        dispatch({
          type: APPLY_SUBSCRIPTION,
          data: result.data,
        });
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: "success",
            message: "Subscription applied successfully",
          },
        });
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: "error",
            message: "Something went wrong",
          },
        });
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const updateUserSubscriptionAction = (payload) => (dispatch) => {
  api
    .post(`admin_master/update/subscriptions`, { data: payload })
    .then((result) => {
      if (result.success) {
        dispatch({
          type: UPDATE_SUBSCRIPTION,
          data: result.data,
        });
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: "success",
            message: "Subscription status updated successfully",
          },
        });
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: "error",
            message: "Something went wrong",
          },
        });
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const updateUserStatusAction = (payload) => (dispatch) => {
  api
    .put(`admin_master/update-user-details`, { data: payload })
    .then((result) => {
      if (result.success) {
        dispatch({
          type: UPDATE_USER,
          data: result.data,
        });
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: "success",
            message: "User status updated successfully",
          },
        });
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: "error",
            message: "Something went wrong",
          },
        });
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const resetUsersDataAction = () => (dispatch) => {
  dispatch({
    type: RESET_USERS,
  });
};

export const changePageNumberAction = (pageNumber) => (dispatch) => {
  dispatch({
    type: UPDATE_PAGE_NUMBER,
    data: pageNumber,
  });
};

export const changePageLimitAction = (limit) => (dispatch) => {
  dispatch({
    type: UPDATE_PAGE_LIMIT,
    data: limit,
  });
};

export const searchTermAddedAction = () => (dispatch) => {
  dispatch({
    type: SEARCH_TERM_ADDED,
  });
};
